/* ++++++++++++++++++++
+++ LAYOUT: GENERAL +++
++++++++++++++++++++ */

// Universal margins
body,
br,
dd,
option,
td,
th,
svg {
    margin-top: 0;
}

html {
  scroll-behavior: smooth;

  @if $fixedhead {
  	scroll-padding-top: $gap * 2;
  }
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "kern"1;
    font-kerning: normal;
    background: $grey15;
    color: $grey20;
    font: $normal 1.125rem/1.5 $font-body;
    position: relative;
    width: 100%;

	&::selection {
        background: $yellow;
        color: $black;
	}
	&::-moz-selection {
        background: $yellow;
        color: $black;
	}
}

//=======================================
// Typography
//=======================================

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 2rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
	&::selection {
        background: $yellow;
        color: $black;/* WebKit/Blink Browsers */
	}
	&::-moz-selection {
        background: $yellow;
        color: $black; /* Gecko Browsers */
	}
}

h1 {
    @extend %h1;
}

h2 {
    @extend %h2;
}

h3 {
    @extend %h3;
}

h4 {
    @extend %h4;
}

h5 {
    @extend %h5;
}

h6 {
    @extend %h6;
}

p, a, div, span, section, figure, article, main, footer, img, button, html, cite, ul, ol, li, time, strong  {
	&::selection {
        background: $yellow;
        color: $black; /* WebKit/Blink Browsers */
	}
	&::-moz-selection {
        background: $yellow;
        color: $black; /* Gecko Browsers */
	}
}

figcaption {
	@extend %font-content;
	background-color: $grey70;
	display: inline-block;
	font-size: 0.9em !important;
	margin-top: 0 !important;
	overflow-wrap: break-word;
	padding: $gap / 2 $gap;
	word-wrap: break-word;
	a {
		@extend %text-hover;
	}
}
figure {
	position: relative;
	overflow: hidden;

	figcaption, .figcaption {
		@extend %transition;
		background-color: $white;
		bottom: -500px;
		left: 0;
		max-width: 100%;
		padding: $gap / 4 $gap / 1.5;
		position: absolute;
		z-index: 999;
	}
	&.active {
		.figcaption-icon {
			opacity: 0;
		}
		figcaption, .figcaption {
			bottom: 0;
		}
	}
}
figure {
	.figcaption-icon {
		@extend %transition;
		@extend %flex;
		align-items: center;
		background-color: $white;
		bottom: 0;
		color: $black;
		font-size: 1.2rem;
		font-weight: $bold;
		justify-content: center;
		position: absolute;
		left: 0;
		height: $gap * 2;
		margin: 0;
		width: $gap * 2;
		cursor: pointer;
		opacity: 1;
		z-index: 999;

		&:hover {
			background-color: $tertiary;
			color: $white;
		}
	}
}

a {
    color: inherit;
    text-decoration: none;
}

strong {
    font-weight: $bold;

}

em {
    font-style: italic;
}

hr {
    border: none;
    border-top: $line solid $grey80;
}

img {
    display: block;
    height: auto;
    max-width: 100%;
    text-align: center;
}

sup {
    vertical-align: super;
    font-size: small;
}

sub {
    vertical-align: sub;
    font-size: small;
}

li {
    list-style: none;
}

//=======================================
// Global placeholders and classes
//=======================================

// Full width section
%section {
    margin: 0;
    overflow: hidden;
    padding: $gap * 1 0;
    width: 100%;

    @include break(medium) {
        padding: $gap * 2 0;
    }
}

// Basic layout for a container (usually within a %section)

.components {
    .container {
        padding: 0 $gap * 2;
        
    }
    #footnotes-list{
        margin-bottom: $gap * 2;
    }
}

%container {
    max-width: $page-width;
    padding: 0 $gap;
    position: relative;
    width: 100%;

    @include break(small) {
        padding: 0 $gap * 2;
    }
}

// Card with a white background and drop shadow
%card {
    background: $white;
    border-radius: $corner;
    overflow: hidden;
}

// Hover styles for links and images
%text-hover {
    @extend %transition;

    &:hover {
        color: $primary;
    }
}

%image-hover {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    display: block;
    overflow: hidden;

    img {
        @extend %transition;
        transform: scale(1);

        &:hover,
        &:focus {
            transform: scale(1.05);
        }
    }
}

// Gives any first paragraphs a drop cap
%drop-cap {
    &>p:first-child::first-letter {
        float: left;
        font-size: 340%;
        line-height: 0.6;
        margin: 10px 10px 0 0;
    }
}

// Add a dark filter
%overlay {
    position: relative;

    &:after {
        background: rgba($black, 0.3);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

%shaded-top,
%shaded-bottom {
    position: relative;

    &:before {
        content: "";
        height: 100%;
        left: 0;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

%shaded-top:before {
    @include linear-gradient(top, to bottom,
        rgba($black, 1) 0%,
        rgba($black, 0.738) 19%,
        rgba($black, 0.541) 34%,
        rgba($black, 0.382) 47%,
        rgba($black, 0.278) 56.5%,
        rgba($black, 0.194) 65%,
        rgba($black, 0.126) 73%,
        rgba($black, 0.075) 80.2%,
        rgba($black, 0.042) 86.1%,
        rgba($black, 0.021) 91%,
        rgba($black, 0.008) 95.2%,
        rgba($black, 0.002) 98.2%,
        rgba($black, 0) 100%);
}

%shaded-bottom:before {
    @include linear-gradient(bottom, to top,
        rgba($black, 1) 0%,
        rgba($black, 0.738) 19%,
        rgba($black, 0.541) 34%,
        rgba($black, 0.382) 47%,
        rgba($black, 0.278) 56.5%,
        rgba($black, 0.194) 65%,
        rgba($black, 0.126) 73%,
        rgba($black, 0.075) 80.2%,
        rgba($black, 0.042) 86.1%,
        rgba($black, 0.021) 91%,
        rgba($black, 0.008) 95.2%,
        rgba($black, 0.002) 98.2%,
        rgba($black, 0) 100%);
}

// Animations

*[data-visible="true"]{
	opacity:1;
	transform: translateY(0), translateX(0);
	transition-timing-function: ease-in-out;
	transition-duration: 800ms;
	transition-delay: 260ms;
}
*[data-scroll-effect="fadein"]{
	opacity: 0.01;
	transform: translateY(50px);
	transition: ease 1.5s;
}
*[data-scroll-effect="fade"]{
	opacity: 0.01;
	transition: ease 0.5s;
}
*[data-scroll-effect="fadeinleft"]{
	opacity: 0.01;
	transform: translateX(-150px);
	transition: ease-in-out 6s;

}
*[data-scroll-effect="fadeinright"]{
	opacity: 0.01;
	transform: translateX(150px);
	transition: ease-in-out 6s;
}

// Sideways text
%sideways {
    @include transform(rotate(180deg));
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -ms-writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
}

// Vertical centered container
%vertical {
    @include transform(translateY(-50%));
    margin-top: 0;
    position: absolute;
    top: 50%;
    width: 100%;
}

.text-hover {
    @extend %text-hover;
}

// Hide things
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
	font-size: 0;
    height: 1px;
    margin: -1px;
	overflow: hidden;
    padding: 0;
    position: absolute !important;
	text-indent: -9999rem;
    width: 1px;
}

.clearfix:after {
    clear: both;
    content: "";
    display: table;
}

.cookie-notice {
    background: $grey20;
    bottom: 0;
    color: $white;
    font-size: 0.9rem;
    line-height: 1.5;
    padding: $gap / 2;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: map-get($zindex, popup);
}

.skip-link {
    border-radius: 0;
    left: 50%;
    position: fixed;
    top: -$gap * 10;
    transform: translateY(-100%);
    transition: transform 0.3s;
    z-index: 999;

    &:focus {
        transform: translateY(0%);
    }
}


//404 page styling
.error404 {
    &-para {
        * {
            margin-top: 1rem !important;
        }
        a {
            text-decoration: underline;
        }

        p {
            color: $blue;
            line-height: 1.4;
        }

        h2 {
            color: $blue;
        }

        li {
            list-style: disc;
            color: $blue;
            margin-top: 0rem !important;

            &::marker {
                color: $pink;
            }

        }

        ul {
            padding-left: 1.2rem;
        }

        .button-cut {
            text-decoration: none;
        }
    }
}


//footnotes plugin styling
.modern-footnotes-footnote__note {
    color: $blue;
}