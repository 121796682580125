/* ++++++++++++++++++++
+++ LAYOUT: WYSIWYG +++
++++++++++++++++++++ */

.wysiwyg {
	margin: 0;
	padding-top: unset;


	&.center {
		.content, .wysiwyg-form {
			margin: 0 auto;
		}

		@include break(medium) {
			width: 66.66%;
			margin: 0 auto;		
		}
	}

	.content, .pullquote, .media-block, .wysiwyg-form {
		margin-top: $gap * 1.5;
		@include break(medium) {

		}
	}
}
