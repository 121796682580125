.location {
	&-outer {
		.section-header {
			color: $primary;
		}
		background-color: $tint-blue;
		&.secondary {
			background-color: $primary;

			.location {
				&-inner {
					&-title {
						color: $white;
					}
					&-block {
						color:  $white;

						span {
							color:  $white;
						}
					}

				}
			}
		}
	}

	&-map {
		background-color: $tint-blue;
		padding: 1.5rem 0 2rem;

		.section-header {
			&-title {
				color: $primary;
			}
			&-summary {
				width: auto;
				color: $primary;
			}
		}

		&.section {
			width: auto;

			// @include break(medium) {
			// 	width: 30%;
			// }

		}

		.location {
			&-inner {
				display: block;
				margin: 0 auto 0 0;
				padding: 0 $gap;

				@include break(small) {
					padding: 0 $gap * 2;
				}

				&-image {
					box-shadow: none;
				}

				&-info {
					display: block;
					padding: 0;
				}

				&-details {
					width: auto;
					margin-right: 0;
					margin-top: 0;

					.location-inner-block {
						+ .location-inner-title {
							padding-top: $gap;
						}
					}
				}

				&-block {
					&.email {
						a {
							word-break: break-word;
						}
					}
				}

				.location-inner-title {
					padding-top: 0;

					.location-inner-title {
						padding-top: $gap;
					}
				}
			}

		}

		.sidebar {
			border: none;
			box-shadow: none;
			border-radius: 0;
			margin-top: 0;
			padding-bottom: 2rem;
			margin-bottom: 0 !important;

			+.sidebar {
				border-top: 5px solid $white;
				margin-top: 0;
				padding-top: 2rem;
				margin-bottom: 0 !important;

				@include break(medium) {
					padding-top: 2rem;
				}
			}

			&:last-of-type {
				padding-bottom: 0;
			}
		}

		.section-header {
			margin: 0 auto;
			text-align: left;

			&-summary {
				font-size: 1.44rem;
				padding-bottom: 0;
			}

			+ .sidebar {
				@include break(medium) {
					padding-top: 1.5rem;
				}
			}
		}
	}

	.section-header {
		color: $primary;
	}

	&-inner {
		@include break(tablet) {
			display: flex;
			margin: 2rem 0;
		}

		&-image {
			height: 18rem;

			@include break(medium) {
				height: auto;
			}

			iframe {
				height: 100%;
				width: 100%;
			}

			img {
				height: 100%;
				width: 100%;
			}

			/* Set the size of the div element that contains the map */
			#map {
			  height: 300px;
			  /* The height is 400 pixels */
			  width: 300px;
			  /* The width is the width of the web page */
			}

			@include break(tablet) {
				max-width: 100%;
				max-height: 100%;
				box-shadow: 15px 15px $white;
				margin-top: unset;
			}

			@include break(medium) {
				max-width: 20rem;
				max-height: 20rem;
			}

			+ .location-inner-info {
				.location-inner-details {
					padding-top: 1rem;
					margin-top: 0;
				}
			}
		}

		&-title {
			font-weight: bold;
			color: $primary;
		}

		&-info {
			margin-top: 0;

			@include break(tablet) {
				display: flex;
				margin-top: unset;
			}
		}

		&-block {
			font-size: 1.2rem;
			color: $primary;

			span {
				font-weight: bold;
				color: $primary;
			}
		}

		&-details {
			margin-right: 1rem;
			line-height: 2rem;

			@include break(tablet) {
				width: 12rem;
			}

			@include break(medium) {
				width: 15rem;
				margin-top: 5rem;
			}

			@include break(large) {
				width: 15rem;
				margin-right: 5rem;
			}

			.email {
				text-decoration: underline;

				&:hover {
					color: $tertiary;
				}
			}

			.telephone {
				&:hover {
					color: $tertiary;
				}
				@include break(tablet) {
					pointer-events: none;
				}
			}

			p {
				@include break(medium) {
					font-size: 1.3rem;
				}
			}

			+.location-inner-details {
				margin-top: $gap;
			}
		}
	}
}
