.benefits {
	&-outer {
		padding-bottom: 0;
		.section-header {
			color: $primary;
			text-align: initial;
			padding: 0;
			margin: auto;
			&-summary {
				margin-left: 0;

				@include break(medium) {
					width: 100%;
				}
			}
		}
	}
	&-inner {
		background-color: $tint-blue;
		padding: $gap * 3;
	}
	&-list {
		list-style: initial;
		color: $primary;
		font-size: 20.25px;
		font-weight: $bold;

		li::marker {
			color: $tertiary;
		}
	}
}
