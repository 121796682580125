/* ++++++++++++++++++++++++
+++ LAYOUT: BREADCRUMBS +++
++++++++++++++++++++++++ */

.breadcrumb {
	font-size: 0.9rem;
	margin-top: 0;
	padding: $gap 0;

	&-outer {
		background-color: $grey95;
		padding: $gap / 1.5 0;
		display: none;
		margin-bottom: 0rem;
		
		@include break(medium) {
			display: block;
		}
	}

	&-list {
		display: block;

		&-item {
			display: inline-block;
			margin-top: 0;

			&:last-child {
				.breadcrumb-list-link {
					&:after {
						display: none;
					}
				}
			}
		}

		&-link {
			@extend %transition;
			color: $primary;
			font-weight: $light;
			padding-right: $gap * 1.3;
			position: relative;

			&:hover {
				color: $primary;
				text-decoration: underline;
			}

			&.current {
				color: $purple;
				font-weight: $bold;
				text-decoration: underline;
			}
			&:after {
				@include centerer(false, true);
				background-image: url("data:image/svg+xml, %3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.707 20.707l-1.414-1.414L14.586 12 7.293 4.707l1.414-1.414L17.414 12z'/%3E%3C/svg%3E%0A");
				background-size: 100%;
				background-repeat: no-repeat;
				content: '';
				display: inline-block;
				height: 16px;
				right: 0;
				width: 16px;
			}
		}
	}
}
