/* ++++++++++++++++++
+++ LAYOUT: CARDS +++
++++++++++++++++++ */

// Cards are more visually interesting and content-light than a standard listing page.

.cards {
    @extend %flex;
	gap: $gap * 2.5;
    position: relative;
    width: 100%;
	margin: 0;
	margin-top: $gap * 2;

	&-noresults {
		color: $tertiary;
	}

    > * {
        @extend %flex;
        @include flexbox(100%);
		margin: 0;

		&:first-child:nth-last-child(1), &:first-child:nth-last-child(1) ~ article {
			@include break(tablet) {
				@include flexbox(47%);
				margin-left: auto;
				margin-right: auto;
			}
		}

		&:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ article {
			@include break(tablet) {
				@include flexbox(47%);
			}
		}
    }

    @include break(tablet) {
        > * {
	        @include flexbox(47%);
        }
    }

    @include break(medium) {
        > * {
	        @include flexbox(31%);
        }
    }
    &-outer {
	    .section-header {
			color: $tertiary;

		    &.container {
			    @include break(medium) {
			    	padding: 0 $gap * 2;
			    }
		    }
	    }
	    .container {
		    @include break(large) {
		    	padding: 0;
		    }
	    }
		&.secondary {
			background-color: $tint-blue;
			.section-header {
				&-title {
					color: $primary;
				}
			}
			.section-footer {
				.button {
					&-cut {
						&:after {
							background-color: $tint-blue;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $tertiary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}

					&-cut-secondary {
						&:after {
							background-color: $tint-blue;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $primary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}
				}
			}
			.card {
				background-color: transparent;

				.button {
					&-cut {
						&:after {
							background-color: $tint-blue;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $tertiary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}

					&-cut-secondary {
						&:after {
							background-color: $tint-blue;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $primary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}
				}
			}
		}
		&.promo {
			.section-header {
				color: $tertiary;
			}
			.card {
				background-color: $tint-blue;
				position: relative;

				&.w-image {
					&:before {
						background-color: $white;
						display: block;
						content: '';
						height: 150px;
						left: 0;
						position: absolute;
						top: 0;
						width: 150px;
					}
				}

				&.hovered {
					.card-header-title {
						color: $primary;
						text-decoration: underline;
					}
				}
				&-header {
					&-title {
						color: $primary;
						padding-bottom: 0.5rem;
						margin-bottom: 0.5rem;
					}
					&-summary {
						color: $black;
					}
				}
				&-wrap-inner {
					background-color: $tint-blue;
					text-align: left;
				}
				.button-cut-secondary {
					&:after {
						background-color: $tint-blue;
					}
					&:where(:hover, :focus) {
						&:after {
							background-color: $primary;
						}
					}
				}

				li {
					list-style: disc;
					margin-left: 1em;
				}
			}
			&.secondary {
				.card {
					background-color: $white;

					&-wrap-inner {
						background-color: $white;
					}
					&.w-image {
						&:before {
							background-color: $tint-blue;
						}
					}
					.button-cut {
						&:after {
							background-color: $white;
						}
						&:where(:hover, :focus) {
							&:after {
								background-color: $tertiary;
							}
						}
					}
				}
			}
		}
		&.listing {
			&.secondary {
				background-color: $tint-blue;

				.card {
					background-color: $white;

					&.w-image {
						&:before {
							background-color: $tint-blue;
						}
					}
				}

			}
			.section-header {
				color: $tertiary;
			}
			.card {
				background-color: $tint-blue;
				display: block;
				position: relative;

				&.w-image {
					&:before {
						background-color: $white;
						display: block;
						content: '';
						height: 150px;
						left: 0;
						position: absolute;
						top: 0;
						width: 150px;
					}
				}

				&.hovered {
					.card-header-title {
						color: $primary;
						text-decoration: none !important;
						border-bottom: 2px solid $secondary;
					}
				}

				&-header {
					&-title {
						color: $primary;
						border-bottom: 2px solid $primary;
						padding-bottom: 0.5rem;
						margin-bottom: 0.5rem;
					}
					&-summary {
						color: $black;
					}
				}
				&-wrap {

					&-inner {
						text-align: left;
						.tags {
							align-self: flex-end;
							width: 100%;
							padding-left: 0;

							.metatag {
								&-link {
									background-color: transparent;
									color: $primary;
									padding-left: 0;
									text-transform: uppercase;
								}
							}
						}
						.metalabel {
							width: 100%;
							padding-bottom: $gap / 2;
							&:hover {
								a {
									color: $primary;
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}
    }
}

.card {
    @extend %card;
	@extend %transition;
	align-content: flex-start;
	display: grid;
	grid-template-rows: max-content auto;
    overflow: hidden;
    width: 100%;

	&.quote {
		pointer-events: none;
		background-color: $tertiary !important;

		.card-header-title {
			border-bottom: none;
		}

		blockquote {
			color: $white;

			p {
				font-weight: $normal;
				font-style: normal;

				@include break(medium) {
					font-size: 1.4rem;
				}
			}
		}
		cite {
			color: $white;
			font-weight: $bold;
			width: 100%;
			padding-top: $gap;
			font-style: normal;

			&:before {
				content: '- ';
				display: inline-block;
				margin-right: 5px;
			}
		}
		.card-wrap {
			align-self: center;
			height: auto;
		}
	}

	.tags {
		align-self: flex-end;
		width: 100%;
		padding-left: $gap;

		+ .card-wrap {
			.card-wrap-inner {
				padding-top: 0;
			}
		}

		.metatag {
			&-link {
				background-color: transparent;
				color: $primary;
				padding-left: 0;
			}
			&:hover {
				.metatag-link {
					text-decoration: underline;
				}
			}
		}
	}
	.metalabel {
		width: 100%;
		padding-bottom: $gap / 2;
	}

	&.hovered {
		transform: scale(1.02);

		.card-header-title {
			color: $secondary;
			text-decoration: underline;
		}
	}

    &-image {
        display: block;
        margin: 0;
		// min-height: $gap * 16;
		// max-height: $gap * 16;
        position: relative;
        width: 100%;

		@include break(medium) {
			height: 16rem;
		}

		/* ---------- BORDER AFFECT ------------ */

		clip-path: polygon(
			// top left point
			$card-edge-size 0,
			// top right
			100% 0,
			// lower right
			100% 100%,
			//bottom left point
			0 100%,
			// top lower left point
			0 $card-edge-size
		);



	    img {
			@include object-fit(4/3, 100%);
	    }
		.card-featured {
			background-color: $primary;
			color: $white;
			font-size: 0.9rem;
			font-weight: $bold;
			left: 0;
			padding: $gap / 2.5 $gap / 1.5;
			position: absolute;
			top: $gap * 1.5;
		}
    }

    &-wrap {
        margin: 0;
		position: relative;
		width: 100%;
		z-index: 2;

        &-inner {
			color: $black;
			display: grid;
			grid-template-rows: max-content max-content auto;
	        padding: $gap * 2 $gap;
			height: auto;
			text-align: center;

			.metalabel {
				width: 100%;
			}
			.tags {
				width: 100%;
			}
        }
    }

	&-button-outer {
		align-self: flex-end;
		width: 100%;
	}

    &-header {
        margin-top: 0;
		width: 100%;
        + .card-summary {
	        margin-top: $gap / 2;
			width: 100%;
        }

        &-title {
			color: $primary;
	        font-size: 1.6em;
			word-break: wrap;
        }

        &-date {
            @extend %font-meta;
			color: $primary;
            display: block;
            margin-top: $gap / 2;
        }
    }
	&-summary {
		color: $primary;
		word-break: wrap;
	}
    &-button {
	    &-outer {
		    margin-top: $gap;
	    }
    }
}

// Report layout

.reports {
	.card {
		&-image {
			background-color: $grey90;
			overflow: hidden;

			img {
				@extend %transition;
				bottom: -1em;
				border-radius: $corner $corner 0 0;
				box-shadow: $shadow;
				margin: $gap * 3 auto 0;
				position: relative;
				width: 60%;

				&:hover {
					bottom: -0.5em;
				}
			}
		}
		&-wrap {
			box-shadow: 0px -8px 22px 0px rgba($black, 0.3);
			z-index: 2;
		}
	}
}
