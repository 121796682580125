/* +++++++++++++++++
+++ LAYOUT: MAIN +++
+++++++++++++++++ */

// Main is the high level container of content, in between the site header and footer

.main {
    background: $body-color;
    display: block;
    margin: 0;

	&-hide {
		display: none;
	}

    &-section {
        @extend %flex;
        @extend %section;
        align-items: flex-start;
        margin: auto;
        max-width: $page-width;
        overflow: visible;
    }

    &-header,
    &-footer {
        text-align: center;
    }

    &-header {
        &-outer {
            background-color: $primary;
            color: $white;
        }
        &-title {
            padding-bottom: $gap;
			padding-top: $gap * 3;
        }
        &-summary {
            p {
                font-size: 1.2em;
            }
        }
    }

    &-section {
	    @include break(medium) {
	        flex-flow: row wrap;
	    }

        > * {
	        @extend %flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: space-between;

	        @include break(medium) {
	            flex: 1 66.66%;
				flex-direction: row;
	        }
        }
	    &.single {
	        > * {
				flex-direction: column-reverse;

		        @include break(medium) {
					flex-direction: row;
		        }
	        }
            .sidebar {
                margin-top: $gap * 2;

                @include break(medium) {
                    margin-top: 0;
                }
            }
	    }
    }
	&-share {
		&-outer {
			@extend %transition;
			background-color: $primary;
			box-shadow: $shadow;
			display: inline-block;
			opacity: 0;
			max-width: 68px;
			padding: $gap $gap $gap / 1.2;
			position: fixed;
			right: 0;
			top: $gap * 10;
			transition: visibility 0s 1s, opacity 1s linear;
			visibility: hidden;
			width: 68px;
			z-index: 9;

			&.share-fix {
				opacity: 1;
				transition: opacity 1s linear;
				visibility: visible;
			}

			.nav-up & {
				top: $gap * 5;
			}
		}
		&-item-link {
			@extend %transition;
			background-color: $white;
			border-radius: 100%;
			border-left: 0;
			display: inline-block;
			height: 36px;
			padding: 0;
			position: relative;
			width: 36px;

			&:hover {
				background-color: $secondary;
			}
			&.email {
				background-image: url("data:image/svg+xml,%3Csvg width='25' height='19' viewBox='0 0 25 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.875 0H3.125C1.40156 0 0 1.42025 0 3.16667V15.8333C0 17.5798 1.40156 19 3.125 19H21.875C23.5984 19 25 17.5798 25 15.8333V3.16667C25 1.42025 23.5984 0 21.875 0ZM18.5625 3.16667L12.5 7.55408L6.4375 3.16667H18.5625ZM3.125 15.8333V4.65975L11.5922 10.7872C11.8641 10.9852 12.1812 11.0833 12.5 11.0833C12.8188 11.0833 13.1359 10.9836 13.4078 10.7872L21.875 4.65975L21.8766 15.8333H3.125Z' fill='black'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 50%;
			}
			.icon {
				@include centerer(true, true);
				color: $black;
				margin: 0;
			}
		}
	}

	.components {
		section {
			&:first-of-type {
				padding-top: 1rem;
			}

			&.wysiwyg,
			&.cards-outer.promo,
			&.cards-outer.listing,
			&.profile-outer,
			&.statistic-outer,
			&.gallery-outer,
			&.embed-outer {
				&.secondary {
					padding-top: $gap * 2;
					margin-top: 1.5rem;

					@include break(medium) {
						padding-top: $gap * 3;
						margin-top: 1.5rem;
					}
				}
			}

			&.media-block-outer,
			&.pullquote-outer {
				padding-top: $gap * 2;

				@include break(medium) {
					padding-top: 2rem;
				}
			}
		}
	}
}
