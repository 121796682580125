/* +++++++++++++++++++
+++ LAYOUT: FOOTER +++
+++++++++++++++++++ */

// Layout of the site footer

.footer {
	background-color: $primary;
	color: $white;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: $gap * 3.5;
    z-index: map-get($zindex, footer);
	position: relative;

	.footer-up {
		position: absolute;
		top: $gap;
		right: $gap * 2;
		background: transparent;
		cursor: pointer;
		gap: 1px;
		font-size: 1.2rem;
		padding: .5rem 0 .5 1rem;
		display: flex;
		z-index: 1;

		@include break(tablet) {
			gap: 8px;
			padding: .5rem 1rem;
		}

		&-btn {
			color: $white;
			background: transparent;
			font-size: 1.2rem;
			font-weight: $bold;
			padding: 0;
			@include break(tablet) {
				font-size: 1.4rem;
				padding: 0;

			}
		}

		&-img {
			width: 1rem;
			@include break(tablet) {
				width: 1.5rem;
			}
		}

		&:hover {
			.footer-up-btn {
				text-decoration: underline;
			}
		}
	}

    &-header {
	    @extend %flex;
	    align-items: center;
	    justify-content: flex-start;
	    padding-bottom: $gap * 2;
		column-gap: $gap;
		padding-top: $gap;
		margin-top: $gap;
		border-top: 1px solid $white;

		@include break(medium) {
			column-gap: 0;
		}
	}

    &-logo {
        display: block;
        margin: $gap 0;
        width: 100%;

        @include break(small) {
	        display: inline-block;
	        margin: 0;
	        width: auto;
        }

        img {
        	width: auto;
        }

    }

    &-nav {
	    &:first-child {
		    border-top: 0;
		    margin: 0;
		    padding-top: 0;
	    }
	    &:nth-child(3) {
		    @include break(small) {
		    	margin: 0;
		    }
		    @include break(medium) {
				@include flexbox(50%);
				text-align: right;

			}
	    }

	    &-outer {
			@extend %flex;
	    }

		.charity-info {
			margin-top: $gap * 0.5;
			text-align: center;

			@include break(medium) {
				max-width: 18rem;
				margin-right: 0;
				text-align: left;
			}
		}

		&-social {
			@include break(medium) {
				max-width: 18.1rem;
				margin-right: 0;
				text-align: left;
			}
		}

	    &.social {
		    @include flexbox(100%);
            margin: 0;
			text-align: center;

            @include break(small) {
	            @include flexbox(auto);
	            text-align: right;
            }
			.footer-nav-links {
				margin-bottom: $gap * 1.2;
				display: inline-block;
				@include break(tablet) {
					display: block;
				}
				@include break(medium) {
					max-width: 18.1rem;
					margin-right: 0;
					text-align: left;
				}

				.button {
					&-cut {
						color: $white;

						&:after {
							background-color: $tertiary;
							background-image: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.92955 19H3.79357C3.24511 19 2.71911 18.7893 2.33129 18.4142C1.94346 18.0391 1.72559 17.5304 1.72559 17V3C1.72559 2.46957 1.94346 1.96086 2.33129 1.58579C2.71911 1.21071 3.24511 1 3.79357 1H7.92955' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.168 15L20.3379 10L15.168 5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.3376 10H7.92969' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
							background-repeat: no-repeat;
							background-size: 1rem;
							background-position: right 1.3rem center;
						}
						&:before {
							background-color: $white;

						}
						&:where(:hover, :focus) {
							background-color: $tertiary;
							color: $tertiary;

							&:after {
								background-color: $white;
								background-image: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.92955 19H3.79357C3.24511 19 2.71911 18.7893 2.33129 18.4142C1.94346 18.0391 1.72559 17.5304 1.72559 17V3C1.72559 2.46957 1.94346 1.96086 2.33129 1.58579C2.71911 1.21071 3.24511 1 3.79357 1H7.92955' stroke='%23DE2773' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.168 15L20.3379 10L15.168 5' stroke='%23DE2773' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.3376 10H7.92969' stroke='%23DE2773' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
							&:before {
								background-color: $tertiary;

							}
						}
					}
				}
			}
	    }

        &-item {
            margin-top: 0;

            .footer-nav-item-title {
	           	@extend %h2;
	            padding-bottom: $gap;
				display: none;

				@include break(tablet){
					display: block;
				}
            }
			.invisible {
				display: none;
				@include break(tablet) {
					display: block;
					color: transparent;
				}
			}

            .nav-item-link {
	            font-size: 1rem;
	            margin: 0;
	            padding: 0 0 $gap / 2 0;

	            &:hover {
					color: $white;
		            text-decoration: underline;
	            }
            }

            &.social {
	            display: inline-block;
	            margin-right: $gap / 4;
	            text-align: center;

	            &:last-child {
		            margin-right: 0;
	            }

	            .nav-item-link {
					@extend %transition;
					background-color: $white;
					border-radius: 100%;
					display: inline-block;
					height: 40px;
		            padding: 0;
					position: relative;
					width: 40px;

					&:hover {
						background-color: $secondary;
						.icon {
							color: $white;
						}
					}

					&.instagram, &.youtube {
						.icon {
							padding: 2px;
						}
					}

					.icon {
						@include centerer(true, true);
						color: $primary;
						margin: 0;
					}
	            }
            }
        }
    }

    p {
		font-size: 0.9em;
    }

	&-nav-outer {
		column-gap: $gap;
		@include break(medium) {
			column-gap: 0;
		}
	}

    &-details, &-nav {
	    @include flexbox(100%);
	    padding-top: $gap * 1.5;
	    margin: 0;
		text-align: center;

	    @include break(small) {

		    border-top: 0;

    	    padding-top: 0;
	    }

		@include break(tablet) {
			@include flexbox(25%);
			text-align: left;

		}

	    @include break(medium) {
			@include flexbox(25%);

	    }
    }

	&-details {
		margin: 0;
		text-align: center;
		@include break(tablet) {
			text-align: left;
		}

        .footer-nav-item-title {
            font-size: 1em;
            padding-bottom: $gap / 2;
			color: $white;
        }
        p {
	        padding-bottom: $gap / 4;

	        a {
		        &:hover {
			        color: $primary;
		        }
	        }
        }
	}

	&-credits {
		background-color: $tertiary;
		color: $white;
		padding: $gap 0;
		margin-bottom: $gap * 3;

		@include break(tablet) {
			margin-top: $gap * 3;
		}
		@include break(medium) {
			margin-bottom: unset;
		}

		&-list {
			text-align: center;
			@include break(tablet) {
				display: flex;
			}
			@include break(medium) {
				text-align: left;
			}
			.nav-item-link {
				margin: 0 .5rem;
				font-size: 1rem;

				&:hover {
					color: $tint-blue;
					text-decoration: underline;
				}
			}
		}

		.container {
			@extend %flex;
			align-items: center;
		}

		p {
			&:first-child {
				margin-left: 0;
			}
		}
		&-logo {
			margin: 0;
			width: 160px;
		}
	}

	.twitter {
		&.nav-item-link {
			&:after {
				content: '𝕏';
				position: absolute;
				top: 50%;
				left: 50%; 
				transform: translate(-50%, -50%);
				color: $blue;
				background: unset;
				bottom: unset;
				height: unset;
				width: unset;
				font-size: 1.3rem;
			}
			&:hover:after {
				color: $white;
				background-color: unset;
			}
		}
		.icon {
			color: transparent !important;
		}
	}

}
