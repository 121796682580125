/* +++++++++++++++++++++++
+++ LAYOUT: PAGINATION +++
+++++++++++++++++++++++ */

// Pagination links for the listing and features pages

%pagination {
    @extend %flex;
    @extend %font-meta;
	align-items: center;
    text-align: center;
    justify-content: space-between;
    flex-wrap:nowrap;

    // @include break(small){
    //     flex
    // }

    a {
        @extend %transition;

        &:hover {
            background: $grey90;
        }
    }

    &-block {
        margin: 0;
        width: 48%;
    }

    &-number {
      display: block;
	  color: $primary;
    }

    &-element {
		outline: none;
        display: block;
        padding: $gap / 4 $gap / 2;
        overflow: hidden;
    }

    &-arrow {
        @extend .button;
        display: block;
    }

    &-current {
        box-shadow: 0 0 0 2px $primary inset;
        color: $primary;
    }

    @include break(tablet) {
        justify-content: center;

        &-number {
            display: block;
        }

        &-block {
            margin: 0 $gap / 4;
            width: auto;
        }

        &-element {
            display: block;
            padding: $gap / 2 $gap;
        }
    }
}

@if $wordpress {
    .pagination {
        @extend %pagination;

        &_block {
            @extend %pagination-block;

            &.next {
                float: right;
            }

            &.numeral {
                @extend %pagination-number;
            }

            &.view_all {
                display: none;
            }
        }

        &_element {
            @extend %pagination-element;
			font-weight: $bold;

            &.prev {
                @extend %pagination-arrow;
				color: $primary;
                font-size: 1rem;
				font-weight: $bold;
				outline: none;
				&:hover {
					background-color: $tint-grey;
					color: $primary;
				}

                @include break(medium){
                  font-size: 1em;
                }
            }

            &.next {
                @extend %pagination-arrow;
				color: $primary;
                font-size: 1rem;
				font-weight: $bold;
				outline: none;
				&:hover {
					background-color: $tint-grey;
					color: $primary;
				}

                @include break(medium){
                  font-size: 1em;
                }
            }

            &.current {
                @extend %pagination-current;
            }
        }
    }
}

@if $drupal {
    .pager {
	    padding: $gap 0;

	    &__items {
	        @extend %pagination;
	    }

        &-first { float: right; }

        &__item {
	        @extend %pagination-block;

	        a {
	            @extend %pagination-element;
				padding: $gap / 2 $gap;
	        }

	        &.is-active {
	            @extend %pagination-element;
	            @extend %pagination-current;

	            a {
		            @include break(small) {
			            padding: 0;
		            }
	            }
	        }

	        &--first a,
	        &--previous a,
	        &--next a,
	        &--last a {
	            @extend %pagination-arrow;
	            @extend %pagination-block;
	        }
        }

        &-ellipsis {
            @extend %pagination-element;
        }
    }
}
