//+++++++++++++++++++++++++++
//+++ HELPERS: TYPOGRAPHY +++
//+++++++++++++++++++++++++++

//=======================================
// Helpers
//=======================================

// Form placeholder color
@include placeholder {
    color: currentColor;
    opacity: 0.4;
}


//=======================================
// Defaults
//=======================================

%font-header {
	font: $bold 1.125rem/1.2 $font-header;
}

%font-body {
	font: $normal 1.125rem/1.2 $font-body;
}

%font-content {
	font: $normal 1.125rem/1.2 $font-content;
}

%font-meta {
	font: $normal 1.125rem/1.5 $font-meta;
}

%font-label {
    font: $bold 0.9rem/1.5 $font-header;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

//=======================================
// Settings
//=======================================
// body:not(.home) {
// 	%h1 {
// 		@include break(medium) {
// 			font-size: calc(1.72rem + 1.4vw);
// 			font-size: 2.813rem;
// 		}
// 	}
// }
%h1 {
	@extend %font-header;
	font-size: 1rem;
	font-size: calc(1.6rem + 1vw);
	font-size: 3rem; 

	//	@include break(tablet) {
	//		font-size: calc(1rem + 1vw);
	//	}
	//
	//	@include break(medium) {
	//		font-size: calc(1.2rem + 1vw);
	//	}
	//
	//	@include break(large) {
	//		font-size: calc(1.72rem + 1.4vw);
	//		font-size: 2.813rem;
	//	}
}

%h2 {
	@extend %font-header;
	color: $blue;
	font-size: 1.8rem;
	font-size: calc(1.6rem + 1vw);
	font-size: 2.5rem;

	@include break(large) {
		font-size: 2.313rem;
		font-size: 2.5rem;
	}
}

%h3 {
	@extend %font-header;
	color: $purple;
	font-size: 1.5rem;
	font-size: calc(1.36rem + 0.7vw);
	font-size: 2.1rem;

	@include break(large) {
		font-size: 2rem;
		font-size: 2.1rem
	}
}

%h4 {
	@extend %font-header;
	color: $blue;
	font-size: 1.4rem;
	font-size: calc(1.32rem + 0.4vw);
	font-size: 1.813rem;

	@include break(tablet) {
		font-size: calc(1rem + 0.4vw);
	}

	@include break(large) {
		font-size: 1.813rem;
	}
}

%h5 {
	@extend %font-header;
	font-size: 1.25rem;
	font-size: calc(1.21rem + 0.2vw);
	font-size: 1.425rem;

	@include break(large) {
		font-size: 1.625rem;
		font-size: 1.425rem;
	}
}

%h6 {
	@extend %font-header;
	font-size: 1.125rem;
	font-size: calc(1.105rem + 0.1vw);

	@include break(large) {
		font-size: 1.438rem;
	}
}
