@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "../../assets/webfonts/slick/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $primary !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 10px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }

    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }

    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick {
    &-list {
        overflow: hidden;
    }

    &-track {
        @extend %flex;
    }

    &-slide {
        flex: 1 auto;
    }
}

/* Icons */
@if $slick-font-family=="slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    background-color: $white;
    border-radius: 100%;
    box-shadow: $shadow;
    cursor: pointer;
    color: transparent;
    display: block;
    font-size: 0px;
    height: 38px;
    line-height: 0px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 38px;
    -webkit-transform: translate(0, -60%);
    -ms-transform: translate(0, -60%);
    transform: translate(0, -60%);
    z-index: 99;

    &:hover,
    &:focus {
        background-color: $white;
        color: transparent;
        outline: none;

        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }

    &:before {
        @include centerer(true, false);
        color: $secondary;
        font-family: $slick-font-family;
        font-size: 30px;
        opacity: $slick-opacity-default;
        top: 21px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-prev {
    &:before {
        content: $slick-prev-character;
    }
}

.slick-next {
    right: 0;
    &:before {
        content: $slick-next-character;
    }
}

/* Dots */

.slick-dotted {
    &.slick-slider {
        margin-bottom: 60px;
    }

}

.slick-dots {
    @include centerer(true, false);
    bottom: -40px;
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        position: relative;
        width: 20px;

        button {
            border: 0;
            background: transparent;
            color: transparent;
            cursor: pointer;
            display: block;
            font-size: 0px;
            height: 20px;
            line-height: 0px;
            outline: none;
            padding: 5px;
            width: 20px;

            &:hover,
            &:focus {
                background-color: transparent;
                outline: none;

                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }

            &:before {
                color: $slick-dot-color;
                content: $slick-dot-character;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                height: 20px;
                left: 0;
                line-height: 20px;
                opacity: $slick-opacity-not-active;
                position: absolute;
                text-align: center;
                top: 0;
                width: 20px;
            }
        }

        &.slick-active button:before {
            color: $tertiary;
            opacity: $slick-opacity-default;
        }
    }
}
