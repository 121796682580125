/* +++++++++++++++++++++
+++ LAYOUT: METADATA +++
+++++++++++++++++++++ */

// Metadata on an article page
.metadata {
    @extend %font-meta;
    color: $secondary;

    &-link {
        @extend %text-hover;
        @extend %transition;
        font-weight: $bold;
    }

    time {
        font-weight: $bold;
    }
}

.metalabel {
    @extend %font-label;
    color: $primary;
    display: block;

    a {

    }
}

// Tags

.tags {
	margin-top: $gap / 2;
}
.metatag {
    float: left;
    margin: $gap / 4 $gap / 4 0 0;
	text-transform: uppercase;

    &-link {
        @extend %transition;
        background-color: $primary;
        border-radius: 25px;
        color: $white;
        display: inline-block;
        font: $bold 0.8em/1.5 $font-body;
        margin: 0;
        padding: 0 $gap / 1.5 0 0;

        &:hover {
           text-decoration: underline;
            color: $tertiary;
        }
    }
}
