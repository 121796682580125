/* ++++++++++++++++++++
+++ LAYOUT: SIDEBAR +++
++++++++++++++++++++ */

// Represents content that is tangentially related to the content nearby, such as tags and subnavigation. within the {% block page %} the sidebar is optional and if included will trigger a two column layout

.sidebar {
	@extend %transition;
	background-color: $tint-blue;
	border-radius: $corner;
	box-shadow: $shadow;
	margin-bottom: $gap;
	width: 100%;

	@include break(medium) {
		@include flexbox(29%);
		margin: -2em 0 0;
		margin: 0 0 2rem 0;
		top: calc(#{$header-height} + (#{$gap} * 2));


		.nav-up &{
			top: $gap * 2;
		}
	}
	@include break(large) {
		@include flexbox(25%);
	}

	&.hide-for-mob {
		display: none;
		@include break(medium) {
			display: block;
		}
	}
	+ .article {
		@include break(medium) {
			margin-right: 0;
		}
	}

	.button {
		&-cut {
			&:after {
				background-color: $tint-blue;
			}
			&:where(:hover, :focus) {
			color: $white;
				&:after {
					background-color: $tertiary;
					background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-repeat: no-repeat;
					background-size: 1rem;
					background-position: right 1.3rem center;
				}
			}
		}

		&-cut-secondary {
			&:after {
				background-color: $tint-blue;
			}
			&:where(:hover, :focus) {
			color: $white;
				&:after {
					background-color: $primary;
					background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-repeat: no-repeat;
					background-size: 1rem;
					background-position: right 1.3rem center;
				}
			}
		}
	}

	&-outer {
		position: relative;
		width: 100%;
		margin-bottom: 1rem;

		.sidebar {
			margin-top: 0 !important;
		}

		.sidebar-show-button {
			background-color: $primary;
			background-position: center right $gap;
			color: $white;
			width: 100%;
			top: $gap / 2;
			text-align: left;
			position: initial;

			span {
				font-size: 1.125rem;
			}

			z-index: 100;
			@include break(medium) {
				display: block;
			}

			&:hover {
				transform: none;
				text-decoration: none;
			}
			&.active {
				transform: none;
			}
		}

		// @include break(medium) {
		// 	@include flexbox(29%);
		// }
		// @include break(large) {
		// 	@include flexbox(25%);
		// }

// 		.sidebar {
// 			&-flex {
// 				@include break(medium) {
// 					@include flexbox(100%);
// 				}
// 				@include break(large) {
// 					@include flexbox(100%);
// 				}
// 				.sidebar {
// 					margin-top: 0;
// 				}
// 			}
// 			&-show-button {
// 				background-color: $primary;
// 				border-radius: 100vmax;
// 				top: $gap / 2;
//
// 				z-index: 100;
// 				@include break(medium) {
// 					display: block;
// 				}
// 			}
// 		}
	}

	&-block {
		margin: 0;
	}

	&-flex {
		margin: 0;
		width: 100%;
		@include break(tablet) {
			display: flex;
			gap: $gap;
		}
		@include break(medium) {
			@include flexbox(29%);
			display: block;
			gap: 0;
		}
		@include break(large) {
			@include flexbox(25%);
		}
	}


	&-show-button {
		@include break(medium) {
			display: none;
		}
		@extend %transition;
		background-image: url('../images/select-arrow.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-color: transparent;
		border: 0;
		display: block;
		height: 30px;
		position: absolute;
		right: $gap;
		top: $gap * 1.4;
		width: 30px;

		&:hover {
			transform: rotate(90deg);
		}
		&.active {
			transform: rotate(180deg);
		}
		@include break(medium) {

		}
	}

	&-inner {
		display: none;

		@include break(medium) {
			display: block !important;
		}
	}

	&-block + &-block {
		margin-top: $gap * 3;
	}

	&-title {
		background-color: $primary;
		color: $white;
		display: block;
		font: $bold 1.2em/1.2 $font-header;
		padding: $gap * 1.5 $gap * 1.5 $gap * 1.2 $gap * 1.5;
		margin: 0 !important;

		@include break(medium) {

		}
	}

	&-block {
		position: relative;
	}

	&-nav {


		@include break(medium) {
			border-top: 0;
			margin: 0;
			padding-top: 0;
		}

		&-item {
			border-bottom: 2px solid $white;
			padding: 0;
			position: relative;

			&-link {
				color: $primary;
				padding: $gap / 3 $gap * 2 $gap / 3 $gap * 1.5;
			}

			.rnav-arrow {
				display: inline-block;
				padding: 0;
				position: absolute;
				right: $gap;

				.icon {
					fill: $primary;
				}
			}

			&.current {
				.sidebar-nav-list {
					display: block;
				}
			}

			.subnav_element {
				@extend %text-hover;
				display: block;

				&:hover, &.current {
					color: $primary;

				}
				&:hover {
					text-decoration: underline;
				}
				&.current {
					background-color: $purple;
					color: $white;

					+ .rnav-arrow {
						&.active {
							.icon {
								fill: $white;
							}
						}
					}
				}
			}

			&.current-menu-item {
				.subnav_element {
					color: $secondary;
					font-weight: $bold;
				}

				.sidebar-nav-item {
					.subnav_element {
						color: inherit;
						font-weight: $normal;
					}
				}
			}
		}
		&-list {
			.sub-list {
				display: none;
				overflow: hidden;
				transition: height 500ms ease;

				& & {
					margin-left: $gap;
				}

				.rnav-arrow {

				}
				&.current-nav {
					display: block;
					max-height: unset;
				}

				.sidebar-nav-item {
					&:last-child {
						border-bottom: none;
					}
					&:first-of-type {

					}
				}

				.sidebar-nav-item-link {
					display: block;
					font-size: 0.9em;
					font-weight: $normal;

					&.current {
						color: $primary;
						font-weight: $bold;
						padding-left: $gap;
					}
					&:hover {
						color: $primary;
						text-decoration: underline;
					}
				}

				.sidebar-nav-item {
						margin-left: $gap;

					&.current-menu-item {
						.sidebar-nav-item-link {
							// box-shadow: inset 2px 0 currentColor;
						}

						.sidebar-nav-item {
							.sidebar-nav-item-link {
								box-shadow: none;
							}
						}
					}
				}

				.sub-list {
					padding-left: $gap;
					.sidebar-nav-item-link {
						font-size: 0.8em;
					}
				}
			}
		}
	}

	&-meta {
		@extend %flex;
		align-items: flex-start;
		justify-content: space-between;
		margin: 0;
		position: relative;

		&-block {
			margin: 0 0 $gap / 1.5 0;

			&.info {
				@include flexbox(100%);

				@include break(small) {
					@include flexbox(48%);
				}
				@include break(medium) {
					@include flexbox(100%);
				}
			}
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&.tags {

			}
			&.image {
				@include flexbox(100%);

				@include break(small) {
					@include flexbox(48%);
				}
				@include break(medium) {
					@include flexbox(100%);
				}

				img {
					@include object-fit(4/5, 100%);
				}
			}

			.smart-date {
				&--time, &--date {
				  font-weight: 500;
				}
				&--time:after, &--date:before {
				  content: ' ';
				  display: block;
				  font-weight: $bold;
				}
			}
			&.press {
				.sidebar-meta-share-title {
					margin-bottom: 0;
				}
				p {
					padding-top: $gap / 2;

					a {
						text-decoration: underline;
					}
				}
			}

			&.headliner {
				font-size: 1.4rem;
				font-weight: bold;
				color: $primary;
			}

			&.summary {
				color: $primary;
				font-weight: bold;
			}

			&.telephone {
				color: $primary;
				font-weight: bold;
			}

			&.date {
				color: $primary;
				font-weight: bold;
			}

			&.author {
				color: $primary;
				font-weight: bold;
			}

			&.price {
				color: $primary;
				text-align: right;
				font-weight: $bold;

				a {
					text-decoration: none;
				}

				a:hover {
					text-decoration: underline;
					color: $primary;
				}

				span {
					text-align: right;
					color:$blue
				}
			}

			&-type {
				@extend %transition;
				color: $primary;
				font-size: $gap;
				text-transform: uppercase;
				font: bold 1.125rem / 1.5 "balinger", "helvetica", sans-serif;

				&:hover {
					text-decoration: underline;
				}
			}

			span {
				color: $tertiary;
				font-weight: $bold;
				text-transform: uppercase;
				width: 100%;
				display: block;
			}
			&-title {
				@extend %font-content;
				color: $tertiary;
				font-weight: $bold;
				text-transform: uppercase;
				margin: 0 $gap / 4 0 0 !important;
				width: 100%;
			}

			&-tag {
				@extend %transition;
				background-color: transparent;
				border-radius: 25px;
				color: $primary;
				display: inline-block;
				font: 1.125rem/1.5 $font-body;
				font-weight: $bold;
				padding-right: $gap / 2;
				text-transform: uppercase;

				&:hover {
					text-decoration: underline;
				}
			}
			&.share {
				justify-content: flex-start;
			}
			&.downloads {
				font-weight: $bold;
				background-color: $white;
				color: $black;
				margin-top: $gap;
				padding: $gap;
				width: 100%;

				.sidebar-meta-share-title {
					margin: 0;
				}

				.icon {
					display: inline-block;
					margin: 0 $gap / 3 0 0;
					width: 20px;
				}

				h3 {
					color: $primary;
					font-size: 1.125rem;
				}
				.downloads-link {
					@extend %transition;
					color: $primary;
					display: block;
					font-size: 1rem;
					padding: $gap / 2 0 0 $gap * 2;
					position: relative;

					&:hover {
						color: $tertiary;
						text-decoration: underline;

						.icon {
							fill: $tertiary;
						}
					}

					.icon {
						left: 0;
						position: absolute;
						top: 4px;
						fill: $primary;
					}
				}
			}
		}
		&-share {
			@extend %flex;
			margin: $gap / 2 0 $gap / 1.5;

			&-title {
				@extend %font-content;
				color: $tertiary;
				font-weight: $bold;
				text-transform: uppercase;
				margin: 0 0 $gap / 2 !important;
				width: 100%;
			}

			&-button {
				@extend %flex;
				@extend %transition;
				align-items: center;
				background-color: $tertiary;
				border-radius: 0;
				height: 40px;
				justify-content: center;
				margin: $gap / 2 $gap / 2 0 0;
				position: relative;
				width: 40px;

				&:hover {
					background-color: $secondary;
				}
				&.twitter, &.instagram {
					.icon {
						height: 1.4em;
						width: 1.4em;
					}
				}
				&.whatsapp, &.link, &.mail {
					.icon {
						height: 1em;
						width: 1em;
					}
				}
				.icon {
					color: $white;
				}

				&.twitter {
						&:after {
							content: '𝕏';
							position: absolute;
							top: 50%;
							left: 50%; 
							transform: translate(-50%, -50%);
							color: $white;
							background: unset;
							bottom: unset;
							height: unset;
							width: unset;
						}
						&:hover:after {
							color: $white;
							background: unset
						}
					.icon {
						color: transparent !important;
					}
				}
			}
		}
	}
}

.sidebar-press-training {
	@extend %transition;
	background-color: $tint-blue;
	border-radius: $corner;
	box-shadow: $shadow;
	margin-bottom: $gap;
	width: 100%;

	@include break(medium) {
		@include flexbox(29%);
		margin: -2em 0 0;
		margin: 0 0 2rem 0;
		top: calc(#{$header-height} + (#{$gap} * 2));


		.nav-up &{
			top: $gap * 2;
		}
	}
	@include break(large) {
		@include flexbox(25%);
	}

	.button {
		&-cut-secondary {
			&:after {
				background-color: $tint-blue;
			}
			&:where(:hover, :focus) {
			color: $white;
				&:after {
					background-color: $primary;
					background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-repeat: no-repeat;
					background-size: 1rem;
					background-position: right 1.3rem center;
				}
			}
		}
	}
}