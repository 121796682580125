/* ++++++++++++++++++++
+++ ESCAPE: SECTION +++
++++++++++++++++++++ */

// An escape button to leave the page currently on which is on the Base level of the page and on every page.

.escape {
	position: absolute;
	top: -2px;
	z-index: 21474836478;

	&-button {
		position: fixed;
		z-index: 99;
		right: 0;
		bottom: 0;
		width: 100%;
		box-shadow: 0 0 8px 0 rgba($black, 0.5);
		border: solid 5px $white;
		@include break(medium) {
			bottom: 0;
			width: auto;
		}
	}
}
