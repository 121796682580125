/* ++++++++++++++++++++
+++ LAYOUT: GALLERY +++
++++++++++++++++++++ */

.gallery {
	margin: $gap * 1.5 0;
	position: relative;

	&-outer {
		.section-header-title {
			color: $tertiary;
		}
	}

	&-item {
        margin: 0;
        text-align: center;
	}
	&-thumb {
		height: 100%;
		padding: 0 $gap;

		img {
			@include object-fit(4/3, 100%);
		}
	}
	.figcaption-icon {
		left: $gap;
	}
}
.slick-arrow {
	display: none !important;

	@include break(small) {
		display: block !important;
	}

	&:hover {
		box-shadow: none;
	}
}

.lg-thumb-item {
	border: 2px solid $white !important;
}
.lg-sub-html {
	color: $white !important;
}
