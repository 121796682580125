body.single-post {
	.main-section {
		.article {
			@include break(medium) {
				margin-left: 0;
			}
		}
		&.single {
			> * {
				@include break(medium) {
					flex-direction: row-reverse;
				}
			}
		}
	}
	&.training {
		.sidebar {
			padding: $gap 0;
			margin: 0;

			background-color: $tint-blue;
			box-shadow: 0 0 0 100vmax $tint-blue;
			-webkit-clip-path: inset(0 -100vmax);
			clip-path: inset(0 -100vmax);

			&-block {
				overflow: hidden;
			}

			&-flex {
				@extend %container;
				margin: auto;

				&:last-child {
					background-color: $white;
					position: relative;
					&:before {
						border-top: $primary solid 5px;
						content: '';
						position: absolute;
						top: 0;
						left: -100vmax;
						width: 200vmax;
						height: 5px;
						z-index: 1;
					}
					&:after {
						border-bottom: $primary solid 5px;
						content: '';
						position: absolute;
						bottom: 0;
						left: -100vmax;
						width: 200vmax;
						height: 5px;
						z-index: 1;
					}
					.sidebar {
						background-color: $white;
						box-shadow: 0 0 0 100vmax $white;
						-webkit-clip-path: inset(0 -100vmax);
						clip-path: inset(0 -100vmax);
					}
				}
			}
			&-meta-block {
				align-self: self-end;
				@include break(medium) {
					margin: 0;
				}
				&-tag {
					padding-left: 0;
					background-color: transparent;
					font-weight: bold;
					color: $primary;
				}
			}
			&-meta-block ul {
				@include break(tablet) {
					display: flex;
					justify-content: space-between;
				}
			}
			&-training {
				gap: $gap;
				margin: 0;

				@include break(small) {
					display: flex;
				}
			}
		}

		.sidebar-press-training {

			.sidebar-meta-block {
				margin: 0 0 $gap / 1.5 0;
			}
			.sidebar-meta-block ul {
				@include break(tablet) {
					display: block;
				}
			}
		}
	}
	.card-outer {
		&.listing {
			.card {
				&-header {
					&-title {
						color: $primary !important
					}
				}
			}
		}
	}
	.sidebar {
		padding: $gap;
		margin-bottom: 1.5rem;
		@include break(medium) {
			@include flexbox(100%);
		}
		@include break(large) {
			@include flexbox(100%);
		}
		&-meta {
			&-block {
				&.info {
					@include break(small) {
						@include flexbox(100%);
					}

				}
			}
		}
	}

	.sidebar-press-training {
		padding: $gap;
		margin-top: 1.5rem;
		@include break(medium) {
			@include flexbox(100%);
		}
		@include break(large) {
			@include flexbox(100%);
		}
	}

	.training-sidebar-section {
		width: 100%;
		margin: 0;
		margin-top: $gap * 2;

		@include break(medium) {
			width: 30%;
			margin: 0;
		}
	}
}
