/* ++++++++++++++++++++++
+++ LAYOUT: PULLQUOTE +++
++++++++++++++++++++++ */

.pullquote {
	@extend %flex;
	font-family: $font-quote;
	justify-content: center;
	margin: 0 auto;
	padding: 0;
	text-align: left;

	@include break(small) {
		align-items: center;
		text-align: left;
	}

	@include break(medium) {
		padding: 0 $gap * 3;
	}

	@include break(xlarge) {
		margin: 0 auto;
		padding: 0;
		width: $page-width;
	}

	&-outer {
		background-color: $tint-blue;

		&.no-quotemarks {
			.pullquote {
				blockquote {
					p:after {
						content: none !important;
					}
					p:before {
						content: none !important;
					}
				}
			}
		}

		&.secondary {
			background-color: $tint-pink;
			.pullquote {
				blockquote {
					color: $purple;
				}
				cite {
					color: $purple;
				}
			}
		}
	}

	&-image {
		@include flexbox(100%);

		@include break(tablet) {
			@include flexbox(30%);
		}

		img {
			width: 100%;
			clip-path: polygon(
				// top left point
				$card-edge-size 0,
				// top right
				100% 0,
				// lower right
				100% 100%,
				//bottom left point
				0 100%,
				// top lower left point
				0 $card-edge-size
			);
		}

		+ .pullquote-info {
			@include flexbox(100%);
			padding: $gap * 1.5 0 0 0;

			@include break(small) {
				@include flexbox(70%);
				padding: 0 0 0 $gap * 2;
			}

			@include break(medium) {
				padding: 0 $gap * 2;
			}

			blockquote {
				color: $primary;
				font-weight: $normal;
				font-size: 1.3rem;

				@include break(medium) {
					font-size: 1.8rem;
				}
			}
		}
	}
	&-info {
		@include flexbox(100%);
	}

	blockquote {
		font-size: 1.3em;
		color: $primary;
		font-weight: $normal;
		padding-bottom: $gap / 1.5;
		quotes: "“" "”" "‘" "’";

		@include break(medium) {
			font-size: 1.3em;
		}

		&:before {
		    content: open-quote;
		    display: inline-block;
		}
		&:after {
		    content: close-quote;
		    display: inline-block;
		}
	}
	cite {
		color: $primary;
		font-size: 1rem;
		font-style: normal;
		font-weight: $bold;

		@include break(medium) {
			font-size: 1.125rem;
		}
		a {
			@extend %transition;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
