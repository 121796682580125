/* +++++++++++++++++++++++++
+++ LAYOUT: TEAM PROFILE +++
+++++++++++++++++++++++++ */

.profile {
	@extend %flex;
	align-items: initial;
	justify-content: center;
	padding: 0 0 $gap * 2;
	gap: $gap;
	@include break(medium) {
		padding: $gap * 2 0;
	}

	&-outer {
		.section-header {
			margin: 0 auto $gap;
			@include break(tablet) {
				margin: 0 auto $gap * 2;
			}
			&-title {
				color: $tertiary;
			}
			&-summary {

				padding-bottom: 0;

			}
		}
		&.secondary {
			.section-header {
				&-title, &-summary {
					color: $white;
				}
			}
			.section-footer {
				.button {
					&-cut {
						&:after {
							background-color: $primary;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $tertiary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}

					&-cut-secondary {
						&:after {
							background-color: $primary;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $primary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}
				}
			}
			background-color: $primary;
			.profile {
				&-wrap {

				}
				&-summary-copy {
					color: $primary;
				}
				&-item {
					&:before {
						@extend %transition;
						content: '';
						background-color: $tertiary;
						height: 100%;
						left: 15px;
						opacity: 0;
						position: absolute;
						top: 15px;
						width: 100%;
						z-index: -1;

						clip-path: polygon(
							// top left point
							$profile-edge-size 0,
							// top right
							100% 0,
							// lower right
							100% 100%,
							//bottom left point
							0 100%,
							// top lower left point
							0 $profile-edge-size
						);
					}
				}
			}
		}
	}

	&-overlay {
		@extend %transition;
		background-color: rgba($blue, 0.7);
		height: 100%;
		opacity: 0;
		left: 0;
		position: fixed;
		top: 0;
		visibility: hidden;
		width: 100%;
		z-index: 99;

		&.active-profile {
			opacity: 1;
			visibility: visible;
		}
	}

	&-element {
		@extend %flex;
		@include flexbox(100%);
		align-items: flex-start;
		justify-content: center;
		margin: $gap 0;
		text-align: center;

		@include break(small) {
			@include flexbox(48%);
		}
		@include break(medium) {
			@include flexbox(31.33%);
		}
		@include break(large) {
			@include flexbox(23%);
		}
		&:hover {
			.profile-item {
				transform: scale(1.02);
				cursor: pointer;

				&:before {
					opacity: 1;
				}
			}
		}
	}

	&-item {
		// @extend %flex;
		@extend %transition;
		align-content: flex-start;
		display: grid;
		grid-template-rows: auto 1fr;
		height: 100%;
		margin: $gap;
		width: 100%;
		position: relative;
		z-index: 2;

		&.no-image {
			grid-template-rows: 1fr;
		}

		&:before {
			@extend %transition;
			content: '';
			background-color: $primary;
			height: 100%;
			left: 15px;
			opacity: 0;
			position: absolute;
			top: 15px;
			width: 100%;
			z-index: -1;

			clip-path: polygon(
				// top left point
				$profile-edge-size 0,
				// top right
				100% 0,
				// lower right
				100% 100%,
				//bottom left point
				0 100%,
				// top lower left point
				0 $profile-edge-size
			);
		}


		&:not(.is-trigger) {
			pointer-events: none;
		}
	}

	&-image {
		@extend %image-hover;
		cursor: pointer;
		display: inline-block;
		margin: 0;
		width: 100%;

		+ .profile-wrap {
			height: auto;
		}

		img {
			aspect-ratio: 1;
			margin: 0;
			width: 100%;
			max-height: 100%;
			object-fit: cover;
			/* ---------- BORDER AFFECT ------------ */

			clip-path: polygon(
				// top left point
				3rem 0,
				// top right
				100% 0,
				// lower right
				100% 100%,
				//bottom left point
				0 100%,
				// top lower left point
				0 3rem
			);
		}
	}
	&-wrap {
		background-color: $tint-blue;
		height: 100%;
		padding: $gap;
		position: relative;
		top: -1px;
		width: 100%;
	}
	&-name {
		color: $primary;
		font-size: 1.3em;
	}
	&-title {
		color: $primary;
		font-size: 1em;
		font-weight: normal;
		padding-top: $gap / 1.5;

		p + p {
			padding-top: $gap / 2;
		}
	}
	&-summary {
		@extend %transition;
		background-color: $white;
		box-shadow: $shadow;
		color: $primary;
		height: 100%;
		margin-top: 0;
		padding: $gap * 5 $gap * 2 $gap * 6;
		position: fixed;
		right: -90%;
		text-align: left;
		top: 0;
		width: 90%;
		z-index: 999;

		@include break(tablet) {
			padding: $gap * 2.5;
			right: -50%;
			width: 50%;
		}
		@include break(large) {
			padding: $gap * 5 $gap * 2.5 $gap * 5 $gap * 5;
			right: -35%;
			width: 35%;
		}

		&.active-profile {
			right: 0;
		}

		&-copy {
			margin-top: $gap;
			color: $primary;
		}

		.profile-summary-close {
			@extend %transition;
			color: $primary;
			cursor: pointer;
			position: absolute;
			right: $gap * 2;
			top: $gap * 2;
			z-index: 999;

			&:hover {
				color: $secondary;
				transform: rotate(90deg);
			}
		}

		.profile-image {
			margin-bottom: $gap;
			width: 60%;
		}

		&-inner {
			display: block;
			margin-top: 0;
			position: relative;
			z-index: 11;
			overflow-y: scroll;
			height: 100%;

			&-content {
				@include flexbox(100%);
				height: 600px;
				margin: 0;

				padding-right: $gap * 2;

				&::-webkit-scrollbar {
				  width: 6px;
				}

				&::-webkit-scrollbar-track {
				  background: transparent;
				}

				&::-webkit-scrollbar-thumb {
				  background-color: #8B94A6;
				  border-radius: 2px;
				}

			}

			.profile-name {
				color: $primary;
				font-size: 1.3em;

				@include break(small) {
					font-size: 1.5em;
				}
			}
			p {
				color: $primary;
				font-size: 0.9em;
				margin-top: 0;
				padding-top: $gap;

				@include break(small) {
					font-size: 1em;
				}
			}
		}
	}
	&-meta {
		margin-top: $gap;
		padding-left: 0 !important;

		&-item {
			display: inline-block;
			padding: $gap / 4 0;

			&-link {
				@extend %transition;
				align-items: center;
				border: none;
				border-radius: 0;
				display: block;
				height: 40px;
				margin-right: $gap / 2;
				position: relative;
				width: 40px;
				background: $secondary;

				&:after {
					content: none !important;
				}

				.icon {
					@include centerer(true, true);
					color: white;
				}

				&:hover {
					background-color: $primary;
					color: $primary;

					.profile-meta-item-text {
						border-bottom: solid 2px $primary;
					}
				}
				&.mail {
					.icon {
						color: $white;
						height: $gap * 1.2;
						width: $gap * 1.2;
					}
				}
			}

			&-text {
				@extend %transition;
				color: $black;
				margin-left: $gap / 1.5;
			}
		}
	}
}
