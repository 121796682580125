/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

.media-block {
	@extend %flex;
	min-height: 420px;

	@include break(medium) {
		flex-wrap: inherit;
	}

	&-outer {
		background-color: $tint-blue;
		padding: 1rem 0;

		@include break(tablet) {
			padding: 2rem 0 3.5rem;
		}

		&.right {
			.media-block {
				align-items: unset;

				@include break(medium) {
					flex-flow: row-reverse nowrap;

				}
				&-element {
					box-shadow: 10px 10px $primary;
					@include break(medium) {
						box-shadow: 25px 25px $primary;
					}
					img {
						@include break(medium) {
							box-shadow: 25px 25px $primary;
						}
					}
				}
			}
		}

		&.left {
			.media-block {
				align-items: center;
			}
		}

	    &.secondary {
			background-color: $tint-pink;
		    .media-block {
				&-element {
					box-shadow: 10px 10px $purple;
					@include break(medium) {
						box-shadow: 25px 25px $purple;
					}
				}
				&-title {
					color: $purple;
				}
				&-summary {
					color: $primary;
				}
				.button {
					&-cut {
						&:after {
							background-color: $tint-pink;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $tertiary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}
					&-cut-secondary {
						&:after {
							background-color: $tint-pink;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $primary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}
					&-cut-quaternary {
						&:after {
							background-color: $tint-pink;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $purple;

							}
						}
					}
				}
		    }
	    }
	    &.video {
		    .media-block {
			    min-height: auto;

			    &-element {
				    @include flexbox(100%);
					overflow: hidden;

					@include break(medium) {
					    @include flexbox(50%);
					}

				    .video-outer {
						display: block;
						padding-bottom: 56.25%; /* 16:9 */
						height: 0;
						position: relative;

						iframe {
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
						}
				    }
			    }
			    &-info {
					@include break(medium) {
					    @include flexbox(50%);
					}
			    }
		    }
	    }
		&.embed {
			.media-block {
				&-element {
					height: fit-content;
				}
			}
		}

		+ .media-block-outer {
			margin-top: 2rem;
			padding: 1rem 0;

			@include break(tablet) {
				padding: 2rem 0 3.5rem;
			}
		}	
	}

	.button {
		&-cut {
			&:after {
				background-color: $tint-blue;
			}
			&:where(:hover, :focus) {
			color: $white;
				&:after {
					background-color: $tertiary;
					background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-repeat: no-repeat;
					background-size: 1rem;
					background-position: right 1.3rem center;
				}
			}
		}
		&-cut-secondary {
			&:after {
				background-color: $tint-blue;
			}
			&:where(:hover, :focus) {
			color: $white;
				&:after {
					background-color: $primary;
					background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-repeat: no-repeat;
					background-size: 1rem;
					background-position: right 1.3rem center;
				}
			}
		}

		&-cut-quaternary {
			&:after {
				background-color: $tint-blue;
			}
			&:where(:hover, :focus) {
			color: $white;
				&:after {
					background-color: $purple;

				}
			}
		}
	}

	&-title {
		margin-bottom: $gap * 1.2;
		margin-top: $gap * 1.2;
		@include break(medium) {
			margin-top: 0;
		}
	}

	&-summary {
		margin-bottom: $gap * 2;
		line-height: 1.5;
	}

    &-element, &-info {
	    margin: 0;
    }
    &-info {
	    @extend %flex;
		align-items: center;
	    color: $primary;
	    padding: $gap * 1.5;

	    @include break(small) {
		    padding: $gap * 3;
	    }

	    @include break(medium) {
		    @include flexbox(50%);
		    padding: $gap * 3;
	    }
	    @include break(large) {
		    padding: $gap * 3 $gap * 3 0;
	    }

	    .button {
		    font-size: 1.2em;
		    margin-top: 2rem;
	    }
    }
    &-content {
	    p {
		    padding-bottom: $gap;

		    a {
			    @extend %text-hover;
			    text-decoration: underline;
		    }
	    }
    }
    &-element {
		@include flexbox(100%);
	    overflow: hidden;
	    position: relative;
		box-shadow: 10px 10px $primary;


	    @include break(medium) {
		    @include flexbox(50%);
			box-shadow: 25px 25px $primary;
	    }

	    &-play {
		    @extend .button-cut;
		    @include centerer(true, true);
		    background-color: $red;
		    display: inline-block;
			padding: $gap * 0.9 $gap * 1.5;
		    width: auto;
		    z-index: 2;

			&:where(:hover, :focus) {
				&:after {
					background-image: none !important;
				}
			}

			&:after {
				background-image: none;
				background-color: $tertiary !important;
			}

			.icon {
				fill: $white;
			}

			&:hover {
				&:after {
					background-image: none;
					background-color: $white !important;
				}
				.icon {
					fill: $tertiary;
				}
			}
	    }

	    img {

            @include object-fit(16/9, 100%);

			/* ---------- BORDER AFFECT ------------ */

			clip-path: polygon(
				// top left point
				$card-edge-size 0,
				// top right
				101% 0,
				// lower right
				101% 101%,
				//bottom left point
				0 101%,
				// top lower left point
				0 $card-edge-size
			);


            @include break(medium) {
	            border-radius: 0;
	            left: 0;
	            position: relative;
	            top: 0;
            }

            .no-objectfit & {
                width: auto;
            }
	    }
    }
    &-title {
	    font-size: 1.4em;

	    @include break(tablet) {
		    font-size: 2em;
	    }
    }
}
