/* ++++++++++++++++
+++ LAYOUT: CTA +++
++++++++++++++++ */

.cta {
    &-outer {
	    background-color: $purple;
	    position: relative;

		&.tertiary {
			background-color: $tint-blue;

			.cta {
				&-title {
					color: $primary;
				}
				&-summary {
					color: $primary;
				}
				.button {
					&-cut {
						color: $primary;
						&:before {
							background-color: $primary;
						}
						&:after {
							background-color: $tint-blue;
							background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='%23002D72' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='%23002D72' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
						}
						&:where(:hover, :focus) {
						color: $white;
							&:before {
								background-color: $primary;
							}

							&:after {
								background-color: $primary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}
					&-cut-secondary {
						&:after {
							background-color: $primary;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $primary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}
				}
			}
		}

		&.secondary {
			background-color: $primary;
			.cta {
				&-title {
					color: $white;
				}
				&-summary {
					color: $white;
				}
				.button {
					&-cut {
						&:after {
							background-color: $primary;
						}
						&:before {
							background-color: $white;
						}
						&:where(:hover, :focus) {
						color: $primary;
							&:after {
								background-color: $white;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='%23002D72' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='%23002D72' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}
					&-cut-secondary {
						&:after {
							background-color: $tint-pink;
						}
						&:before {
							background-color: $white;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $primary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}
				}
			}
		}

	    &.full-width-image {
		    padding: 0;

		    @include break(tablet) {
				min-height: 80vh;
		    }

		    .cta-image {
				position: relative;

				@include break(tablet) {
					position: absolute;
				}
		    }
	    }

	    &.site-width-image {
		    padding: 0;

		    @include break(tablet) {
				min-height: 60vh;
		    }
		    @include break(large) {
			    margin: 0 auto;
		    	width: $page-width;
		    }

		    .cta-image {
				position: relative;

				@include break(tablet) {
					position: absolute;
				}
		    }
	    }

	    .cta-image {
		    + .cta-inner {
				background-color: rgba($white, 0.8);
				border-radius: $corner;
				box-shadow: $shadow;
				color: $black;
				margin: 4vh;

				@include break(small) {
					margin: 8vh;
				}
				@include break(medium) {
					margin: 8vh auto;
				}
		    }
	    }
		.cta-inner {
			color: $white;
			padding: $gap * 1.5;
			text-align: center;
			position: relative;
			z-index: 1;

			@include break(small) {
				padding: $gap * 3;
			}
			@include break(medium) {
				width: 75%;
			}

			.button {
				&-cut {
					color: $white;
					&:after {
						background-color: $purple;
						background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					}
					&:before {
						background-color: $white;
					}
					&:where(:hover, :focus) {
					color: $purple;
						&:after {
							background-color: $white;
							background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='%238E3A80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='%238E3A80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
							background-repeat: no-repeat;
							background-size: 1rem;
							background-position: right 1.3rem center;
						}
					}
				}
				&-cut-secondary {
					&:after {
						background-color: $tint-blue;
					}
					&:where(:hover, :focus) {
					color: $white;
						&:after {
							background-color: $primary;
							background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
							background-repeat: no-repeat;
							background-size: 1rem;
							background-position: right 1.3rem center;
						}
					}
				}
				&-cut-tertiary {
					&:after {
						background-color: $purple;
					}
					&:where(:hover, :focus) {

						&:after {
							background-color: $tertiary;
							background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
							background-repeat: no-repeat;
							background-size: 1rem;
							background-position: right 1.3rem center;
						}
					}
				}
				&-cut-quaternary {
					&:before {
						background-color: $white;
					}
				}
			}
		}
    }

	&-title {
		color: $white;
	}

 	&-summary {
		color: $white;
		font-size: 1.266rem;
	 	padding: $gap * 1.5 0 $gap * 2;
 	}

 	&-image {
	 	height: 100%;
	 	left: 0;
	 	position: absolute;
	 	top: 0;
	 	width: 100%;

	 	img {
		 	@include object-fit(16/9, 100%);
	 	}
 	}
    &-caption {
	    background-color: $primary;
	    color: $white;
		font-size: 0.8em;
	    padding: $gap / 1.5 $gap * 1.5;


		@include break(tablet) {
			bottom: 0;
			left: 0;
			position: absolute;
		}
    }
}
