.embed {
	&-outer {
		.section-header {
			&-title {
				 color: $tertiary;
			}

			&-summary {
				&.content p {
					font-size: 1em;

					@include break(small) {
						font-size: 1.125em;
					}
				}
			}
		}
		.container {
			text-align: center;

			iframe {
				margin: auto !important;
				width: 100%;
			}
		}
	}
}


#mc_embed_signup{

	width: 100%;
	@include break(medium) {
		width: 75%;
	}
	@include break(large) {
		width: 70%;
	}
	
}


