/* ++++++++++++++++++++
+++ LAYOUT: ARTICLE +++
++++++++++++++++++++ */

// Article represents the part of a page that includes posted content including title and summary. Displaying as an inline-block allows the article to center nicely with or without a sidebar present.

.article {
    margin-top: 0;
    padding: $gap * 2 $gap 0;
    position: relative;
    width: 100%;

    @include break(small) {
	    padding: $gap * 2 $gap;
    }

    &-header {
        &-summary {
            p {
                font: $light 1.33em/1.5 $font-header;
            }
        }
    }

    &-footer {
        border-top: $line solid $grey80;
        margin-top: $gap * 2;
        padding-top: $gap;
    }

    @include break(small) {
        padding: 0;
    }

    @include break(medium) {
	    @include flexbox(66.66%);
    }


    //Inline components

	> * + * { margin: $gap * 2 0 0 }

	.inline-header {
		+ div {
			margin-top: 0;
		}

		&-title {
			font-size: 1.7rem;
			margin: 0;
			padding-bottom: $gap / 2;
		}
		&-summary {
			font-size: 1.125em;
			padding-bottom: $gap;
		}
	}

    .accordion-outer, .pullquote {
		width: 100%;
    }
    .accordion-outer {
	    background-color: $grey90;
    }
    .pullquote {
		align-items: flex-start;
		color: $primary;
		padding: $gap * 2;
		margin: 1rem 0 0 0;
		background-color: $tint-blue;
		clip-path: polygon(
			// top right point
			100% 0,
			// bottom right point
			100% calc(100% - #{$card-edge-size}),
			// bottom right point
			calc(100% - #{$card-edge-size}) 100%,
			//bottom left lower point
			0 100%,
			// Top left point
			0 0
		);

		@include break(tablet) {
			margin: $gap * 2;
		}

	    &-image {
			+ .pullquote-info {
				blockquote {
					font-size: 1.3em;
				}
			}
	    }
		blockquote {
			font-size: 1.3em;
		}
	    cite {
		    font-size: 1em;
	    }
    }
	.gallery {
		&-flex {
			h2 {
				color: $primary;
			}
		}
		&-thumb {
			padding: 0;
		}
		.slick-slide {
			* {
				height: 100%;
			}
		}
		.slick-dotted {
			&.slick-slider {
				margin-bottom: 60px;
			}

		}
		.slick-prev {
				@include break(tablet) {
					left: -$gap;
				}
		}

		.slick-next {
			@include break(tablet) {
				right: -$gap;
			}
		}
	}
	.download {
		&-block {
			@include flexbox(100%);

			@include break(small) {
				@include flexbox(50%);
			}
		}
	}
	.cta {
		background-color: $grey90;
		border-radius: $corner;
		padding: $gap * 3;

		&-inner {
			text-align: left;
		}
	}
	.media-block {
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;
		display: block;
		min-height: auto;

		&-element {
			@include flexbox(100%);
			border-radius: 0;
			box-shadow: none;

			img {
				position: relative;
			}
			.video-outer {
				display: block;
				padding-bottom: 56.25%; /* 16:9 */
				height: 0;
				position: relative;

				iframe {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}
			}
		}
		&-caption {
			background-color: $white;
			display: inline-block;
			color: $primary;
			font-size: 1rem !important;
			margin-top: 0 !important;
			overflow-wrap: break-word;
			padding: $gap / 2 0;
			word-wrap: break-word;
			margin: 0;
		}
	}
	.statistic {
		row-gap: $gap * 1.5;
		.inline-header {
			color: $primary;
			text-align: center;
			@include break(tablet) {
				text-align: left;
			}
		}
		&-title {
			display: inline;
			font-size: 1.5rem;
		}
		&-value {
			color: $tertiary;
			display: inline-flex;
			line-height: 1;
			text-align: center;

			@include break(small) {
				text-align: left;
			}
			
			@include break(tablet) {
				@include flexbox(auto);
			}
			span, &-number {
				font-size: 1.65rem;
			}
		}
		&-content {
			color: $primary;
			text-align: center;
			@include break(tablet) {
				@include flexbox(100%);
				text-align: left;
			}
		}
		&-image {
			@include flexbox(100%);
			@include break(tablet) {
				@include flexbox(15%);
				height: auto;
			}
			img {
				width: 25%;

				@include break(tablet) {
					width: 75%;
				}
			}
			+ .statistic-content {
				@include flexbox(100%);
				text-align: center;
				@include break(tablet) {
					@include flexbox(82%);
					text-align: left;
				}
			}
		}
		&-item {
			@include break(tablet) {
				@include flexbox(100%);
			}

			@include break(medium) {
				@include flexbox(100%);
				margin: 0;

				&:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ .statistic-item {
					@include flexbox(100%);
				}
				&:first-child:nth-last-child(3), &:first-child:nth-last-child(3) ~ .statistic-item {
					@include flexbox(100%);
				}
			}
			&-wrapper {
				@extend %flex;
				align-items: flex-start;
				gap: $gap;

			}
		}
	}
	.profile {
		margin: $gap * 2 (-$gap * 1.5);

		@include break(medium) {
			margin: $gap * 2 (-$gap * 2);
		}
		&-element {
			margin: $gap * 1.5 0;

			@include break(large) {
				@include flexbox(33.33%);
			}
			&:nth-child(n+4), &:nth-child(n+5) {
				margin: $gap * 1.5 0;
			}
		}
	}
}
