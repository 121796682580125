// X cancel/escape
@mixin escape($color) {
	background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.1465 2.31818L2.58691 21.8295' stroke='white' stroke-width='3' stroke-linecap='square' stroke-linejoin='round'/%3E%3Cpath d='M2.58691 2.31818L21.1465 21.8295' stroke='white' stroke-width='3' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E");
}

// Arrow & button icons
@mixin button-arrow($color) {
	background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='#{$color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='#{$color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-size: 100%;
}


// Footer login icon
@mixin arrow-login($color) {
	background-image: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.92955 19H3.79357C3.24511 19 2.71911 18.7893 2.33129 18.4142C1.94346 18.0391 1.72559 17.5304 1.72559 17V3C1.72559 2.46957 1.94346 1.96086 2.33129 1.58579C2.71911 1.21071 3.24511 1 3.79357 1H7.92955' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.168 15L20.3379 10L15.168 5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.3376 10H7.92969' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-size: 100%;
}
// Header login
@mixin login-man($color) {
	background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.5 21V19C20.5 17.9391 20.0786 16.9217 19.3284 16.1716C18.5783 15.4214 17.5609 15 16.5 15H8.5C7.43913 15 6.42172 15.4214 5.67157 16.1716C4.92143 16.9217 4.5 17.9391 4.5 19V21' stroke='%238E3A80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.5 11C14.7091 11 16.5 9.20914 16.5 7C16.5 4.79086 14.7091 3 12.5 3C10.2909 3 8.5 4.79086 8.5 7C8.5 9.20914 10.2909 11 12.5 11Z' stroke='%238E3A80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-size: 100%;
}
