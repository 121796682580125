/* ++++++++++++++++++++
+++ LAYOUT: SECTION +++
++++++++++++++++++++ */

// A section is a grouping of content, typically with a header, possibly with a footer. A '.section' with a '.container' results in a consistent paddings, margins and max-width for content while allowing for full width background colors on the section and centred background colors on the container.

.section {
    @extend %section;

    &.secondary {  background: $secondary; }

    &.secondary {
        .section-header, .section-footer, .main-header {
            color: $white;
        }
        &.wysiwyg, &.statistic-outer, &.download-outer, &.pullquote-outer, &.profile-outer {
            color: $white;
        }
    }

    &.subnav {
	    background: $white;
    }

    &-header {
	    margin: 0 auto $gap * 2;
        text-align: center;

        @include break(small) {
            margin: 0 auto $gap * 2;
        }

        &-title {
	        + .section-header-summary {
		        padding-top: $gap / 1.5;
	        }
        }

        &-summary {
			color: $primary;
	        font-size: 1rem;
            padding-bottom: $gap;

            @include break(small) {
	            font-size: 1.125rem;
            }

            @include break(medium) {
            	padding: 0 0 $gap;
            	width: 75%;
            }

			p + p {
				padding-top: $gap / 1.5;
			}
        }
    }

    &-footer {
        @extend %container;
        margin-top: $gap;
        text-align: center;

        @include break(small) {
	        margin-top: $gap * 2;
        }
    }
}

.container {
    @extend %container;
}
