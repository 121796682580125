/* +++++++++++++++++++++++
+++ LAYOUT: STATISTICS +++
+++++++++++++++++++++++ */

.statistic {
	@extend %flex;
	padding: 0;
	column-gap: 3rem;
	justify-content: flex-start;
    text-align: center;

    @include break(large) {
	    padding: 0;
    }

	&-outer {
		padding-bottom: unset;
		.section-header {
			&-title {
				color: $tertiary;
			}
		}
		.section-footer {
			margin-top: 0rem;
			.button {
				&-cut-tertiary {
					&:where(:hover, :focus) {
						&:after {
							background-color: $white;
						}
						&:before {
							background-color: $primary;
						}
					}
				}
			}
		}
		&.secondary {
			background-color: $tint-pink;
			.section-header {
				&-title {
					color: $purple !important;
				}
				&-summary {
					color: $primary;
				}
			}
			.section-footer {
				.button {
					&-cut {
						&:after {
							background-color: $tint-pink;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $tertiary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}

					&-cut-secondary {
						&:after {
							background-color: $tint-pink;
						}
						&:where(:hover, :focus) {
						color: $white;
							&:after {
								background-color: $primary;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
					}

					&-cut-tertiary {
						&:before {
							background-color: $primary;
						}
						&:where(:hover, :focus) {
							&:after {
								background-color: $tint-pink;
							}
							&:before {
								background-color: $primary;
							}
						}
					}
					&-cut-quaternary {
						&:after {
							background-color: $tint-pink;
						}
						&:where(:hover, :focus) {
							&:after {
								background-color: $purple;
							}
							&:before {
								background-color: $purple;
							}
						}
					}
				}
			}
			.statistic {
				&-item-wrapper {
					color: $purple;
				}
				&-summary, &-source {
					color: $primary;
				}
				&-value {
					color: $purple;
				}
				&-title {
					color: $primary;
				}
			}
		}
	}

    &-item {
		*{
			max-width: 100%;
		}
    	@include flexbox(100%);
    	margin: 0 0 $gap * 2;
		

    	@include break(tablet) {
	    	@include flexbox(46%);
    	}

    	@include break(medium) {
	    	@include flexbox(30%);
			margin: 0 0 $gap * 2 0;

		    &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ .statistic-item {
	            @include flexbox(48%);
		    }
		    &:first-child:nth-last-child(3), &:first-child:nth-last-child(3) ~ .statistic-item {
	            @include flexbox(30%);
		    }
    	}

    	&:last-child {
	    	margin-bottom: $gap;

	    	@include break(small) {
	    		margin: 0 0 $gap * 2;
	    	}
    	}
    }

	&-item-wrapper {
		color: $primary;
		max-width: 90%;
	}

	&-image {
		@include break(medium) {
			height: auto;
		}

		img {
			height: unset;
			object-fit: contain;
			width: 65%;
			/* clip-path: polygon(
				// top left point
				$profile-edge-size 0,
				// top right
				100% 0,
				// lower right
				100% 100%,
				//bottom left point
				0 100%,
				// top lower left point
				0 $profile-edge-size
			); */

		}
	}

    &-icon {
        font-size: 2rem;
    }

	&-prefix, &-suffix {
		font-size: 2.1rem;
		font-weight: $bold;
	}

    &-value {
	    display: inline-block;
		color: $tertiary;
	    text-align: center;

        &-number {
          font-size: 2.3rem;
		  font-weight: $bold;
        }
    }
    &-title {
		font-size: 1.4rem;
		padding: 0 0 $gap / 2;
		position: relative;
    }
	&-source {
		font-size: 0.9rem;
		padding: $gap 0 0;
		position: relative;
	}
	a.link-underline {
		text-decoration: underline;
	}
	a[target=_blank] {
		&:after {
			@extend %transition;
			background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3333 9.88897V15.2223C14.3333 15.6938 14.146 16.146 13.8126 16.4794C13.4792 16.8128 13.0271 17.0001 12.5556 17.0001H2.77778C2.30628 17.0001 1.8541 16.8128 1.5207 16.4794C1.1873 16.146 1 15.6938 1 15.2223V5.44453C1 4.97303 1.1873 4.52085 1.5207 4.18745C1.8541 3.85405 2.30628 3.66675 2.77778 3.66675H8.11111' stroke='%23002D72' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.6667 1H17.0001V6.33333' stroke='%23002D72' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.22217 10.7778L16.9999 1' stroke='%23002D72' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			background-size: 100%;
			content: '';
			display: inline-block;
			height: 16px;
			margin-left: $gap / 2;
			width: 16px;
		}
	}
}

