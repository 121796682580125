/* +++++++++++++++++
+++ LAYOUT: HERO +++
+++++++++++++++++ */

body.home {
	.hero-title {
		@extend %h1;
		padding: 0;
	}
}

.hero {
	@extend %flex;
	position: relative;

	@include break(medium) {
		flex-flow: row-reverse nowrap;
	}

	&-outer {
		padding: 0;
		&.content-center {
			.hero-inner {
				text-align: center;
				.hero-title, .hero-summary {
					margin: auto;
				}
			}
		}
		&.center-text {
			.hero-info {
				text-align: center;
				.hero-title, .hero-summary {
					margin: auto;

				}
				.hero-summary {
					p {
						padding-right: 0;
					}
				}
			}
		}
		&.full {
			.hero-image {
				@include flexbox(100%);
				height: 100%;
				width: 100%;

				@include break(tablet) {
					left: 0;
					position: absolute;
					top: 0;
				}
				img {
					@include object-fit(32/9, 100%);
				}
				+ .hero-info {
					@include flexbox(100%);
					background-color: rgba($white, 0.7);
					box-shadow: $shadow;
					position: relative;
					text-align: center;
					z-index: 2;

					@include break(tablet) {
						@include flexbox(80%);
						border-radius: $corner;
						margin: 10em auto;
					}

					@include break(medium) {
						@include flexbox(50%);
					}

					.hero-inner {
						color: $black;
						margin: 0;
						padding: 3rem;
						width: auto;
					}
				}
			}
			.hero-info {
				.button {
					text-decoration: none;

					&-cut {
						color: $white;

						&:after {
							background-color: $primary;
							background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='nonexmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='roundstroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
							background-repeat: no-repeat;
							background-size: 1rem;
							background-position: right 1.3rem center;
						}
						&:before {
							background-color: $white;

						}
						&:where(:hover, :focus) {
							background-color: $white;
							color: $primary;
							&:after {
								background: $white;
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='nonexmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='%23002D72' stroke-width='2stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='%23002D72stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
								background-repeat: no-repeat;
								background-size: 1rem;
								background-position: right 1.3rem center;
							}
						}
		// 				&-secondary {
		// 					&:after {
		// 						background-color: $primary;
		// 					}
		// 					&:before {
		// 						background-color: $white;
		//
		// 					}
		// 					&:where(:hover, :focus) {
		// 						background: $white;
		// 						color: $primary;
		// 						&:after {
		// 							background: $white;
		// 						}
		// 					}
		// 				}
					}
				}
			}
			.hero-summary {
				p {
					padding: 0;
				}
			}
		}
		&.solid {
			.hero {
				@include break(medium) {
					flex-flow: nowrap;
				}
				&-info {
					p {
						@include break(medium) {
							padding-right: 0;
						}
					}
				}
				&-image {
					@include flexbox(100%);
					@include break(medium) {
						@include flexbox(60%);
					}
					+ .hero-info {
						@include flexbox(100%);
						background-color: $secondary;
						box-shadow: none;

						@include break(medium) {
							@include flexbox(40%);
						}

						.hero-inner {
							background-color: $white;
							color: $primary;
							text-align: left;

					    	@include break(medium) {
								position: relative;
								left: -10rem;

								clip-path: polygon(
									// top right point
									100% 0,
									// bottom right point
									100% calc(100% - #{$pb-edge-size}),
									// bottom right point
									calc(100% - #{$pb-edge-size}) 100%,
									//bottom left lower point
									0 100%,
									// Top left point
									0 0
								);
					    	}
						}
					}
				}
			}
		}
		&.listing {
			text-align: center;

			.hero-info {
				background-color: $secondary;

				.hero-inner {
					margin: 0 auto;

					@include break(medium) {
						width: $page-width / 1.5;
					}
					.hero-title, .hero-summary {
						@include break(medium) {
							width: 100%;
						}
					}
				}
			}
		}
	}
	&-info, &-image {
		margin: 0;
	}
	&-info {
		@extend %flex;
		@include flexbox(100%);
		background-color: $primary;
		align-items: center;
		justify-content: center;

		.hero-summary {
			padding-top: $gap;
		}

		p {
			@include break(medium) {
				padding-right: 5rem;
			}
		}
		a {
			margin-top: 2rem;

		}

	}
	&-inner {
		@extend %container;
		color: $white;
		margin: 0 auto;
		padding-top: $gap * 3;
		padding-bottom: $gap * 3;
		margin: 1rem;

		@include break(tablet) {
			width: 80em;
		}

		@include break(medium) {
			padding-top: $gap * 4;
			padding-bottom: $gap * 4;
			margin: 0;
		}
		.hero-title, .hero-summary {
			word-break: wrap;

			@include break(medium) {
				margin-left: 0;
				width: 66.66%;
			}
		}
	}
	&-image {
		@include flexbox(100%);
		overflow: hidden;
		position: relative;

		@include break(medium) {
			@include flexbox(50%);
		}
		+ .hero-info {
			@include break(medium) {
				@include flexbox(50%);
			}
			.hero-inner {
				margin: 0 0 0 auto;
				padding: 3rem 2rem;

				@include break(medium) {
					width: 40em;
					padding: 4rem 3rem 4rem 2rem;
				}
				@include break(large) {

					padding: 4rem;
				}
				.hero-title, .hero-summary {
					@include break(medium) {
						margin-left: 0;
						width: 100%;
					}
				}
			}
		}
		img {
			@include object-fit(16/9, 100%);
		}
	}
}
