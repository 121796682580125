body:not(.home) {
  .hero {
	  &-image {
		  + .hero-info {
			.hero-inner {
				margin: 1rem;
				@include break(medium) {
					margin: 0;
				}
			}
		  }
	  }
  }
}
body.home {
	.hero {
		&-image {
			@include break(medium) {
				@include flexbox(60%);
			}
			+ .hero-info {
				@include break(medium) {
					@include flexbox(40%);
					position: relative;
					z-index: 1;

					&:after {
						background-color: $primary;
						content: '';
						position: absolute;
						height: 135%;
						width: 100%;
						top: 0;
						right: -13.5rem;
						z-index: -1;

						clip-path: polygon(
							// top right point
							100% 0,
							// bottom right point
							100% calc(100% - #{$hero-edge-size}),
							// bottom right point
							calc(100% - #{$hero-edge-size}) 100%,
							//bottom left lower point
							0 100%,
							// Top left point
							0 0
						);
					}
				}
				@include break(xlarge) {
					&:after {
						content: '';
						position: absolute;
						right: -16.5rem;
						height: 130%;
					}
				}
				@include break(xlarge) {
					&:after {
						content: '';
						position: absolute;
						right: -16.5rem;
						height: 120%;
					}
				}
				@include break(xxlarge) {
					&:after {
						content: '';
						position: absolute;
						right: -25.5rem;
						height: 101%;
					}
				}
				@include break(monitor) {
					&:after {
						content: '';
						position: absolute;
						right: -30.5rem;
					}
				}
				.hero-inner {
					@include break(medium) {
						padding-right: 0;
					}
				}
			}
		}
	}
	.cards-outer {
		&.promo {
			.section-header {
				color: $pink;
			}
		}
		&.listing {
			.section-header {
				color: $pink;
			}
		}
	}
	.media-block {
		&-outer {
			&.secondary {

				.media-block {
					&-content {
						.button {
							&-cut {
								&:after {
									background-color: $white;
								}
								&:where(:hover, :focus) {
								color: $white;
									&:after {
										background-color: $tertiary ;
										background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
										background-repeat: no-repeat;
										background-size: 1rem;
										background-position: right 1.3rem center;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
