.popup {
	background-color: $blue;
	color: $white;
	padding: 0.8rem;
	display: flex;

	@include break(medium) {
		padding: $gap * 0.5;
	}

	&-details {
		text-align: start;
		width: 100%;
		padding: 0 $gap * 1 0 0;
		align-self: center;

		@include break(tablet) {
			padding: 0;
			text-align: center;
		}

		p {
			font-size: small;

			@include break(tablet) {
				font-size: medium;
			}

			@include break(medium) {
				font-size: large;
			}
		}

		a {
			text-decoration: underline;
			font-weight: bold;

			&:hover {
				color: $tint-blue;
			}
		}


// 		&:after {
// 			content: '';
// 			display: block;
// 			right: $gap / 1.5;
// 			transition: margin .2s;
// 			width: 23.5px;
// 			position: relative;
// 			pointer-events: all;
// 		}
//
// 		&:after {
// 			@include escape('');
// 			width: 2rem;
// 			height: 2rem;
// 			top: 8px;
// 			background-repeat: no-repeat;
// 			position: absolute;
// 		}
	}

	&-x {
		@include escape('');
		width: $gap * 2;
		height: $gap * 1.5;
		// top: 8px;
		background-repeat: no-repeat;
		position: relative;
		cursor: pointer;
	}
}

.popup.active {
	display: none;
}
