/* +++++++++++++++++++++++++++
+++ LAYOUT: RESPONSIVE NAV +++
+++++++++++++++++++++++++++ */

// Layout and styling of the navigation at mobile and tablet sizes

.rnav {
    color: $white;
    margin-top: 0;
    padding-top: 0;

    &-outer {
		background-color: $white;
        display: none;
        overflow: hidden;

        @include break(medium) {
            display: none !important;
        }

		.header-links {
			margin: $gap;
		}
    }

    &-search {
		background-color: $primary;
        padding: $gap;

        .search {
            width: 100%;

			&-input {

				&:hover {
					&::placeholder {
						color: $purple;
					}
					+ .search-button {
						.search-icon {
							fill: $purple;
						}
					}
				}
			}

			&:hover {
				.search-button {
					.search-icon {
						fill: $purple;
					}
				}
			}

            &-input {
                background: rgba($white, 0.2);
                border-color: transparent;
                color: $white;
                font-size: 1em;
                height: auto;
                padding: $gap / 1.5 $gap $gap / 1.5 $gap * 3;

				&:hover {
					width: 100%;

				}
            }
            &-icon {
                height: 24px;
                right: $gap / 1.8;
                width: 24px;
				fill: $white;
            }
            &-button {
                border-left: $line solid rgba($white, 0.1);
                width: 45px;
            }
        }
    }

	&-item {
		border-top: $line solid rgba($primary, 0.1);
		position: relative;

		&-link {
			@extend %transition;
			display: block;
			color: $primary;
			margin: $gap / 3.5 0;
			padding: $gap $gap * 3 $gap $gap;
			text-align: left;

			&:hover {
				background: rgba($primary, 0.1);
			}
		}
	}

	&-dropdown {
		background: rgba($tint-blue, 0.5);
		display: none;
		overflow: hidden;
		transition: height 500ms ease;
	}

	&-secnav {
		background: rgba($primary, 1);

		&-item {
			border-bottom: $line solid rgba($white, 0.1);
			display: block;

			&:last-child {
				border-bottom: 0;
			}

			.rnav-item-link {
				color: $white;
				font-size: 1.125rem;
				font-weight: $normal;

				&:hover {
					background: lighten($tint-blue, .5);
					border-color: $primary;
					color: $primary;
				}
			}
		}
	}

	&-arrow {
		@extend %transition;
		background-color: transparent;
		box-shadow: none;
		cursor: pointer;
		padding: $gap;
		position: absolute;
		right: 0;
		top: $gap / 3.5;
		width: auto;

		&:hover {
			background-color: transparent;
			box-shadow: none;
		}

		&.active {
			transform: rotate(-180deg);
		}

		.icon {
			@include transition(transform 0.4s ease);
			display: block;
			fill: $primary;
			width: $gap;
		}
	}

	&-button {
		border-top: $line solid rgba($white, 0.1);
		padding: $gap;
	}
}
