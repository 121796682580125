/* +++++++++++++++++++
+++ LAYOUT: SEARCH +++
+++++++++++++++++++ */

.search {
	display: inline-block;
	margin: 0;
	position: relative;
	width: 100px;

	&-input {
		font-size: 1rem;
		height: 1.8em;
		margin-top: 0;
		padding: 0 $gap / 2 0 $gap * 2.5;
	

		&::placeholder {
			color: white;
		}

		&:hover {
			color: $purple;
			width: 200px;
			background: $tint-grey;

			@include break(medium){
				width: 130px;
			}

			@include break(large){
				width: 200px;
			}

			.home & {
				border-color: $white;
				color: $purple;
			}

			&::placeholder {
				color: $white;
				.home & {
					color: $purple;
				}
			}
		}
	}

	&-icon {
	    @include centerer(false, true);
		fill: $tertiary;
		cursor: pointer;
		height: 20px;
		display: inline-block;
		right: $gap / 3.5;
		pointer-events: none;
		width: 20px;


		@include break(medium) {
			fill: $purple;
		}

		&:hover {
			.search-button {
				background-color: $secondary;
			}
		}
	}
	&-button {
		outline: none;
		background-color: transparent;
		height: 100%;
		overflow: hidden;
		padding: 0;
		position: absolute;
		left: 0;
		text-indent: -999rem;
		top: 0;
		width: 28px;

		&:hover {
			background-color: $tint-pink;
		}

		&::before{
			background-color: $primary;
		}

		&::after{
			background: none;
		}
	}
}
