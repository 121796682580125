/* +++++++++++++++++++
+++ LAYOUT: HEADER +++
+++++++++++++++++++ */

// Site header bar
.header {
	background: $header-color;
	position: relative;
	width: 100%;
	z-index: map-get($zindex, header);

	.container {
		@extend %flex;
		flex-flow: column-reverse;
		padding: 0;
		@include break(medium) {
			padding: 0 $gap * 2;
		}
	}

	&-inner {
		@extend %flex;
		@include flexbox(100%);
		justify-content: space-between;
		margin: 0 ;
		padding: $gap;


		@include break(medium) {
			padding: $gap;
		}
	}
	&-logo {
		margin-left: 0;

		img {
			min-width: 7rem;
			@include break(medium) {
				position: absolute;
				bottom: $gap;
				left: 2rem;
				min-width: 10.5rem;
			}
			@include break(large) {
				min-width: 12.5rem;
			}
		}
	}

	&-links {
		 margin: 0;

		@include break(medium) {
			display: inline-block;
			padding: 0 $gap 0 $gap / 2;
		}
		.button-cut {
			color: $purple;
			padding: $gap * 0.6 $gap * 2 $gap * 0.6 $gap * 2;

			&:before {
				background-color: $purple;
				z-index: -2;
			}
			&:after {
				background-color: white;
				background-image: unset;
				background-repeat: no-repeat;
				background-size: 1rem;
				background-position: left .8rem center;
			}
			&:where(:hover, :focus) {
				background: $tertiary;
				color: $white;
				&:after {
					background-color: $purple;
					background-image: unset;
					background-repeat: no-repeat;
					background-size: 1rem;
					background-position: left .8 center;
				}
			}
		}
	}
	&-nav {
		margin-right: 0;
		&-list {
			display: none;

			@include break(medium) {
				display: block;
				margin: 0;
			}
			.nav-item-link {
				display: flex;
				align-items: center;
				color: $tertiary;
				font-weight: $bold;
				font-size: 0.8rem;

				@include break(medium) {
					width: 6.5rem;
					padding-top: .5rem;
					padding-bottom: .5rem;
					height: 3rem;
				}
				@include break(large) {
					font-size: 1rem;
					width: 8rem;
					height: 3.5rem;
				}
			}
		}
	}
	&-subnav {
		

		&-inner {
			@include flexbox(100%);

			display: none;
			padding: $gap / 4 $gap;
			text-align: right;
			width: 100%;

			@include break(medium) {
				display: block;
			}
			.search {
				width: 95px;
				transition: 1s ease-in-out;
				
				&:hover {
					width: 200px;
					transition: 1s ease-in-out;
					
					@include break(medium){
						width: 95px;
					}
					@include break(large){
						width: 200px;
					}

					.search-input {
						background-color: $tint-grey;

						&::placeholder {
							color: $purple;
						}
					}
				}
				&-button {
					left: 0;
					border-left: none;
			  	  &::before, &::after {
					display: none;
			  	  }
			 	  &:hover {
					border: none;
			  	  }
				}
				&-input {
					padding: 0 $gap / 2 0 $gap * 2;
					height: 2.5rem;
					border: none;
				}
			}
		}

		@include break(medium) {
			display: inline-block;

			&-list {
				text-align: right;

				.subnav-item {
					display: inline-block;

					.nav-item-link {
						color: $primary;
						font-size: 1rem;
						margin: 0 $gap / 2;
						@include break(medium) {
							padding: 0.5rem 0;
							margin: 0 0.2rem;
						}
						@include break(large) {
							margin: 0 $gap / 2;
						}
						&:hover {
							color: $purple;
						}
					}
				}
			}
		}
	}
}

// Navigation items in the header and footer
.nav-item {
	float: left;

	&:last-child {
		position: relative;
		.dropdown {
			right: 0;
		}
	}

	@include break(medium) {
		&:before {
			position: absolute;
			content: '';
			display: block;
			border-left: 2px solid $tertiary;
			height: 2.5rem;
			width: 1px;
			margin-top: 6px;
		}

	}
	@include break(large) {
		&:before {
			position: absolute;
			content: '';
			display: block;
			border-left: 2px solid $tertiary;
			height: 2.5rem;
			width: 1px;
			margin-top: 6px;
		}

	}

	&:focus-within .dropdown, &:hover .dropdown {
		@include transition(opacity 0.2s 0.3s ease);
		opacity: 1;
		visibility: visible;

		.nav-item {
			float: none;
		}
	}
	&.hovered {
		background: $tertiary;
		color: white;
		box-shadow: 2px 0 0 0 $tertiary;
		z-index: 11;
		position: relative;


		.nav-item-link {
			color: white;
		}
		&:last-child {
			.dropdown {
				right: 0;
			}
		}
		.dropdown-item {

			.nav-item-link {
				box-shadow: none;
				color: $primary;
			}
		}
	}

	.button {
		margin-left: $gap;
	}

	&-link {
		@extend %text-hover;
		display: block;
		margin: 0 $gap * 1 0 $gap * 0.5;
		padding: $gap / 2 0 0;

		@include break(large) {
			margin: 0 $gap * 2 0 $gap * 0.5;
		}

		.social & {
			margin: 0;
			padding: $gap / 2 $gap;
		}

		// &.current {
		// 	box-shadow: inset 0 -2px 0 $tertiary;
		// }
	}

	&-arrow {
		width: $gap;
	}
}

// Responsive nav hamburger button
.menu-button {
	background-color: transparent;
	color: $tertiary;
	cursor: pointer;
	font-size: 1.4rem;
	font-weight: $bold;
	margin: $gap / 5 0 0 $gap;
	padding: 0 $gap * 1.5 0 0;
	position: relative;
	text-transform: uppercase;
	width: auto;

	&:hover {
		background-color: transparent;
		box-shadow: none;
		color: $primary;
	}

	@include break(medium) {
		display: none;
	}
	.icon {
		@include centerer(false, true);
		height: 1em;
		right: 0;
		width: 1em;
	}
}

// Dropdown menu styling
.dropdown {
	@include transition(visibility 0s 0.4s ease, opacity 0.2s 0.2s ease);
	background: $header-color;
	border-radius: 0 0 $corner $corner;
	margin-top: 0;
	opacity: 0;
	position: absolute;
	visibility: hidden;
	white-space: nowrap;
	width: fit-content;

	&-item {
		border-bottom: 1px solid $tint-grey;
		white-space: nowrap;
		&:hover {
			background-color: $tint-blue;
		}
		.nav-item-link {
			color: $primary;
			width: fit-content;
			margin: 0;
			padding: $gap / 2 $gap;

		}
	}
}

// CMS tweaks
@if $wordpress {
	#wpadminbar {
		box-sizing: content-box;
		margin-top: 0;

		img {
			display: inline;
		}

		@media screen and (max-width: 600px) {
			position: fixed;
		}
	}
}

@if $drupal {
	.header-nav-outer {
		.nav-item-link {
			&.is-active {
			  color: $grey15;
			  font-weight: $bold;
			}
		}
	}
}
