/* ++++++++++++++++++++
+++ LAYOUT: FILTERS +++
++++++++++++++++++++ */

.filter {
	@extend %flex;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: $page-width;
    padding: $gap * 1.5 $gap 0;

    @include break(small) {
	    padding: $gap $gap $gap * 1.5;
    }

    &-outer {
	    background-color: $primary;

	    &.small {
		    .filter {
			    max-width: $half-column;
		    }
	    }

		&.search-listing {
			.filter-block {
				&:last-child {
					@include break(tablet) {
						flex: 0 12em;
						padding: 0 $gap;
					}
				}
			}
		}
    }

    &-button {
        text-align: center;

    }

    &-show-button {
        @extend %transition;
        background-color: transparent;
        background-image: url('../images/select-arrow.svg');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 20%;
        cursor: pointer;
        display: block;
        margin: 0 auto;
        padding: $gap $gap * 2 $gap 0;

        @include break(tablet) {
            display: none;
        }

        span {
            color: $white;
			@extend %h6;
        }
    }
    &-inner {
        display: none;

        @include break(tablet) {
            display: block !important;
        }
    }

    &-block {
        flex: 0 100%;
        padding: 0 0 $gap * 1.5;
        text-align: left;

        @include break(small) {
            padding: 0 $gap * 2.5 $gap * 1.5;
        }

        @include break(tablet) {
	        flex: 1 12em;
	        padding: 0 $gap;
        }

        label {
	        color: $white;
	        font-weight: $bold;
			font-size: 1.125rem;
        }

        select {
			background-position: 94% center;
			min-height: $gap * 2.8;
        }

        input {
            height: 2.5rem;
        }

		&-submit {
            align-items: flex-end;
			display: flex;

			.filter-clear {
				color: white;
                height: 2.5rem;
				outline: none;
				padding: 0;
				margin-left: $gap;

				&:hover {
					background-color: transparent;
					color: $tint-blue;
					text-decoration: underline;
				}
			}
		}
    }

    &-submit {
	    background-color: $white;
		outline: none;
        width: 100%;
		font-weight: $bold;
        line-height: 2.5rem;
        padding: 0;

        @include break(small) {
		    margin-top: 0;
        }

        &:hover {
	        background-color: $tertiary;
	        color: $white;
			outline: $white 1.5px solid;
        }
    }
}
