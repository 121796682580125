//++++++++++++++++++++++++
//+++ HELPERS: BUTTONS +++
//++++++++++++++++++++++++

// Button mixin
%button {
    @extend %transition;
    @extend %font-body;
    display: block;
	font-weight: $bold;
    padding: $gap * .9 $gap;
    text-align: center;
	text-decoration: none;
    width: 100%;

    @include break('small') {
        display: inline-block;
        width: auto;
    }
}

/* STANDARD BUTTON - rectangle, NO clipped edge or arrow - x3 variations */
.btn,
.button {
	@extend %button;
	// box-shadow: 0 0 2px $tertiary;
	outline: 2px solid $tertiary;
	color: $tertiary;

	&:hover {
		background: $tertiary;
		color: $white;
	}

	&:active {

	}
}

.btn-secondary,
.button-secondary {
	@extend %button;
	// box-shadow: 0 0 2px $tertiary;
	outline: 2px solid $primary;
	color: $primary;

	&:hover {
		background: $primary;
		color: $white;
	}


	&:active {
		background: rgba($black, 0.1);
	}
}

.btn-tertiary,
.button-tertiary {
	@extend %button;
	// box-shadow: 0 0 2px $tertiary;
	outline: 2px solid $white;
	color: $white;
	background-color: transparent;

	&:hover {
		background: $white;
		color: $primary;
	}


	&:active {
		background: rgba($primary, 0.1);
	}
}

.btn-quaternary,
.button-quaternary {
	@extend %button;
	// box-shadow: 0 0 2px $tertiary;
	outline: 2px solid $purple;
	color: $purple;
	background-color: transparent;

	&:hover {
		background: $purple;
		color: $white;
	}


	&:active {
		background: rgba($primary, 0.1);
	}
}

/* -------------------------------- */

/* BRANDED CLIPPED BUTTON - Clipped edges with arrow - x3 variations */
.button-cut {
	@extend %button;
	// outline: 2px solid $tertiary;
	background-color: white;
	color: $tertiary;
	padding: $gap * .9 $gap * 2.8 $gap * .9 $gap;
	position: relative;


	/* ---------- BORDER AFFECT ------------ */

	clip-path: polygon(
		// top right point
		100% 0,
		// bottom right point
		100% calc(100% - #{$edge-size}),
		// bottom right point
		calc(100% - #{$edge-size}) 100%,
		//bottom left lower point
		0 100%,
		// Top left point
		0 0
	);

	&:before, &:after {
		content: '';
		position: absolute;
		inset: 0;
	}
	&:before {
		background-color: $tertiary;
		z-index: -2;
	}
	&:after {
		background-color: white;
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='%23DE2773' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='%23DE2773' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-size: 1rem;
		background-position: right 1.3rem center;

		display: initial;
		z-index: -1;
		clip-path: polygon(
			// top right point
			calc(100% - #{$border-width}) $border-width,
			// bottom right point
			calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// bottom right point
			calc((100% - #{$border-width}) - #{$edge-size} * .938) calc(100% - #{$border-width}),
			//bottom left lower point
			$border-width calc(100% - #{$border-width}),
			// Top left point
			$border-width $border-width,
		);

		transition: clip-path 400ms;
	}
	/* ---------- BORDER AFFECT ------------ */


	&:where(:hover, :focus) {
		background: $tertiary;
		color: $white;
		&:after {
			background-color: $tertiary;
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-size: 1rem;
			background-position: right 1.3rem center;

			// BUTTON HOVER ANIMATION
			// clip-path: polygon(
			// 	// top right point
			// 	calc(100% - #{$border-width}) $border-width,
			// 	// bottom right point
			// 	calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// 	// bottom right point
			// 	calc((100% - #{$border-width}) - #{$edge-size} * .938) calc(100% - #{$border-width}),
			// 	//bottom left lower point
			// 	calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// 	// Top left point
			// 	calc(100% - #{$border-width}) calc(100% - #{$border-width}),
			// );
		}
	}

	&:active {

	}
}
.button-cut-secondary {
	@extend %button;
	color: $primary;
	padding: $gap * .9 $gap * 2.8 $gap * .9 $gap;
	position: relative;

	&:hover {
		background: $primary;
		color: $white;
	}

	&:active {

	}

	clip-path: polygon(
		// top right point
		100% 0,
		// bottom right point
		100% calc(100% - #{$edge-size}),
		// bottom right point
		calc(100% - #{$edge-size}) 100%,
		//bottom left lower point
		0 100%,
		// Top left point
		0 0);

	&:before, &:after {
		content: '';
		position: absolute;
		inset: 0;
	}
	&:before {
		background-color: $primary;
		z-index: -2;
	}
	&:after {
		background-color: $white;
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='%23002D72' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='%23002D72' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-size: 1rem;
		background-position: right 1.3rem center;
		display: initial;
		z-index: -1;
		clip-path: polygon(
			// top right point
			calc(100% - #{$border-width}) $border-width,
			// bottom right point
			calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// bottom right point
			calc((100% - #{$border-width}) - #{$edge-size} * .938) calc(100% - #{$border-width}),
			//bottom left lower point
			$border-width calc(100% - #{$border-width}),
			// Top left point
			$border-width $border-width,
		);

		transition: clip-path 400ms;
	}
	/* ---------- BORDER AFFECT ------------ */


	&:where(:hover, :focus) {
		background: $primary;
		color: $white;
		&:after {
			background-color: $primary;
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-size: 1rem;
			background-position: right 1.3rem center;
			// clip-path: polygon(
			// 	// top right point
			// 	calc(100% - #{$border-width}) $border-width,
			// 	// bottom right point
			// 	calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// 	// bottom right point
			// 	calc((100% - #{$border-width}) - #{$edge-size} * .938) calc(100% - #{$border-width}),
			// 	//bottom left lower point
			// 	calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// 	// Top left point
			// 	calc(100% - #{$border-width}) calc(100% - #{$border-width}),
			// );
		}
	}
}

.button-cut-tertiary {
	@extend %button;
	color: $white;
	padding: $gap * .9 $gap * 2.8 $gap * .9 $gap;
	position: relative;

	&:hover {
		background: $white;
		color: $white;
	}

	&:active {

	}

	clip-path: polygon(
		// top right point
		100% 0,
		// bottom right point
		100% calc(100% - #{$edge-size}),
		// bottom right point
		calc(100% - #{$edge-size}) 100%,
		//bottom left lower point
		0 100%,
		// Top left point
		0 0);

	&:before, &:after {
		content: '';
		position: absolute;
		inset: 0;
	}
	&:before {
		background-color: $white;
		z-index: -2;
	}
	&:after {
		background-color: $primary;
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-size: 1rem;
		background-position: right 1.3rem center;
		display: initial;
		z-index: -1;
		clip-path: polygon(
			// top right point
			calc(100% - #{$border-width}) $border-width,
			// bottom right point
			calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// bottom right point
			calc((100% - #{$border-width}) - #{$edge-size} * .938) calc(100% - #{$border-width}),
			//bottom left lower point
			$border-width calc(100% - #{$border-width}),
			// Top left point
			$border-width $border-width,
		);

		transition: clip-path 400ms;
	}
	/* ---------- BORDER AFFECT ------------ */


	&:where(:hover, :focus) {
		background: $tertiary;
		color: $white;
		&:after {
			background-color: $tertiary;
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-size: 1rem;
			background-position: right 1.3rem center;
			// clip-path: polygon(
			// 	// top right point
			// 	calc(100% - #{$border-width}) $border-width,
			// 	// bottom right point
			// 	calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// 	// bottom right point
			// 	calc((100% - #{$border-width}) - #{$edge-size} * .938) calc(100% - #{$border-width}),
			// 	//bottom left lower point
			// 	calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// 	// Top left point
			// 	calc(100% - #{$border-width}) calc(100% - #{$border-width}),
			// );
		}
	}
}

.button-cut-quaternary {
	@extend %button;
	color: $purple;
	padding: $gap * .9 $gap * 2.8 $gap * .9 $gap;
	position: relative;

	&:hover {
		background: $white;
		color: $white;
	}

	&:active {

	}

	clip-path: polygon(
		// top right point
		100% 0,
		// bottom right point
		100% calc(100% - #{$edge-size}),
		// bottom right point
		calc(100% - #{$edge-size}) 100%,
		//bottom left lower point
		0 100%,
		// Top left point
		0 0);

	&:before, &:after {
		content: '';
		position: absolute;
		inset: 0;
	}
	&:before {
		background-color: $purple;
		z-index: -2;
	}
	&:after {
		background-color: $white;
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='%238E3A80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='%238E3A80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-size: 1rem;
		background-position: right 1.3rem center;
		display: initial;
		z-index: -1;
		clip-path: polygon(
			// top right point
			calc(100% - #{$border-width}) $border-width,
			// bottom right point
			calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// bottom right point
			calc((100% - #{$border-width}) - #{$edge-size} * .938) calc(100% - #{$border-width}),
			//bottom left lower point
			$border-width calc(100% - #{$border-width}),
			// Top left point
			$border-width $border-width,
		);

		transition: clip-path 400ms;
	}
	/* ---------- BORDER AFFECT ------------ */


	&:where(:hover, :focus) {
		background: $white;
		color: $white;
		&:after {
			background-color: $purple;
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.00012 1L13.0001 7L7.00012 13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-size: 1rem;
			background-position: right 1.3rem center;
			// clip-path: polygon(
			// 	// top right point
			// 	calc(100% - #{$border-width}) $border-width,
			// 	// bottom right point
			// 	calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// 	// bottom right point
			// 	calc((100% - #{$border-width}) - #{$edge-size} * .938) calc(100% - #{$border-width}),
			// 	//bottom left lower point
			// 	calc(100% - #{$border-width}) calc((100% - #{$border-width}) - #{$edge-size} * .938),
			// 	// Top left point
			// 	calc(100% - #{$border-width}) calc(100% - #{$border-width}),
			// );
		}
	}
}
/* -------------------------------- */

// Escape button styling
.btn-escape {
	@extend %button;
	@extend %h3;
	display: flex;
	align-items: center;
	font-weight: $bold;
	height: 3.5rem;
	width: 100%;

	background: $yellow;
  color: $black;
	// opacity: 0.8;

	border: none;
	padding: 1.5rem 2rem 1.5rem 2rem;
	justify-content: center;


	@include break(medium) {
		height: 6rem;
		text-align: start;
	}

	&:hover {
		text-decoration: underline;
	}

	&:active {
		background: $primary-light;
	}
}

